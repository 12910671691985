import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSetRecoilState} from 'recoil'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import loginimg from '../images/loginimg.webp'
import logo from '../images/blacklogo.png';
import googlelogo from '../images/googlelogo.png';
import userAtom from '../atoms/userAtom';
import Loading from '../components/Loading';
import { Helmet } from 'react-helmet-async';


const Login = () => {

	const setUser = useSetRecoilState(userAtom);
  const [loading, setLoading] = useState(false)
  const router = useNavigate()
  const location = useLocation()

  const GoogleLogin = async()=>{
    if(loading) return
    setLoading(true)
    try {
      window.open(`${process.env.React_App_IP}/auth/google/callback`, "_self")
    } catch (error) {
      console.log(error)
    }
  }


  const getUser = async()=>{
    try {
      const res = await axios.get(`${process.env.React_App_Server}/users/login`, {withCredentials: true})
      if(res.error){
        toast.warn("Login Failed")
      }
      localStorage.clear();
      localStorage.setItem("jwtToken", (res.data.token));
      res.data.token = null;
      localStorage.setItem("user-threads", JSON.stringify(res.data));
			setUser(res.data);
      if(location.pathname === '/login'){
        setTimeout(() =>{
          router("/") 
        },100)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    getUser()
  },[])

  if(loading){
    return <Loading />
  }
  
  return (
    <>
       <Helmet>
            <title>OrKhylo  -Login</title>
            <meta name="description" content="Join OrKhylo and Kickoff your Career"></meta>
            <link rel='canonical' href='/login'/>
        </Helmet>
    <div className=" sm:grid grid-cols-7 min-h-screen  bg-slate-50 text-black">
        <div className='hidden sm:flex relative col-span-1 sm:col-span-5 w-full h-full overflow-hidden '>
          <img className='absolute h-full w-full rou object-cover' src={loginimg} alt=''/>
        </div>
        <div className="sm:col-span-2 flex justify-center items-center text-center h-full w-full bg-slate-50"> 
          <div className='h-[60%] w-[80%] mx-auto grid justify-around mt-20'>
            <div className='w-full'>
              <div className='flex '>
                <img className='relative h-6 w-10 md:h-10 md:w-16' src={logo} alt=''/>
                <div className='text-neutral-900 font-semibold text-base md:text-2xl'>OrKhylo</div>
              </div>
              <div className='flex justify-center opacity-70 mt-1 mb-10'>
                <Link to={"/privacypolicy"} className='flex text-[10px] '>Read Privacy Policy of OrKhylo</Link>
              </div>
            </div>
            
                
            <div className="w-full grid justify-center">
              <div className="mb-10 text-base md:text-2xl">
                Signin with your Account
              </div> 
           
              <button onClick={GoogleLogin} className="flex justify-center gap-3 py-2 h-10 md:h-12 w-[80%] mx-auto hover:bg-gray-100 rounded-lg text-sm md:text-lg font-medium border border-neutral-400 shadow text-neutral-500" ><img className='h-6 w-6 md:h-8 md:w-8' src={googlelogo} alt='' />Google</button>
              <div className='flex justify-center opacity-70 mt-1 mb-10'>
                <Link to={"/termsofservice"} className='flex text-[10px] '><span className='text-blue-700 font-medium mr-2'>Terms of service</span> of OrKhylo</Link>
              </div>
            </div>
            
          </div>
        </div>
    </div>
    <ToastContainer />
    </>
  )
}

export default Login