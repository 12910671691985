import React, {useEffect, useState} from 'react'
import location from '../../images/location.png'
import { ToastContainer ,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import left from '../../images/left-arrow.png'
import right from '../../images/right-arrow.png'
import deleteicon from '../../images/icons8-delete-30.png'
import updateicon from '../../images/icons8-update-50.png'
import {  useRecoilState, useRecoilValue } from 'recoil';
import userAtom from '../../atoms/userAtom';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Loading, SlotModal } from '../../components';
import { Helmet } from 'react-helmet-async';
import groundAtom from '../../atoms/groundAtom';

const MyGround = () => {

  const user = useRecoilValue(userAtom);
  const today = new Date();
  const[groundA, setGroundA] = useRecoilState(groundAtom);
  const[ground, setGround] = useState("")
  const[loading, setLoading] = useState(false)
  const[slotloading, setSlotLoading] = useState(false)
  const [day, setDay] = useState(today.toDateString().split(" ")[0]);
  const [slots, setSlots] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const[modalOpen, setModalOpen] = useState(false);
  const [slot, setSlot]= useState(null)
  const [slotId, setSlotId]= useState(null)

  const getGround = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${process.env.React_App_Server}/grounds/owner/${user?._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        }
      }); 
      const data = await res.json(); // updated user object
      if (data.error) {
        toast.error(data.error);
        return;
      }
      setGround(data)
      setGroundA(data)
      
    } catch (error) {
      console.log(error);
      toast.error("No Ground Found");
    } 
    finally{
      setLoading(false);
      getSlots()
    }
  };

  const getSlots = async () => {
    if(slotloading) return
    setSlotLoading(true)
    try {
      const res = await axios.get(`${process.env.React_App_Server}/slots?day=${day}&groundId=${user?.groundId}`);
      setSlots(res.data)
    } catch (error) {
      toast.error("No Ground Found");
    }finally{
      setSlotLoading(false)
    } 
  };

  const handleSlots=(d)=>{
    setDay(d)
    getSlots()
  }

  const handleModel =()=>{
    setModalOpen(false)
    getSlots()
}

 useEffect(()=>{
  if(user.groundaccess){
    getGround();
  }
},[])

const handleDelete = async() => {
  try{
      const res = await fetch(`${process.env.React_App_Server}/slots/${slotId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        }
      });
      const data = await res.json(); // updated user object
      if (data.error) {
        toast.error(data.error);
        return;
      }
      console.log(data)
      toast.info("Slot Deleted Successfully!")
      
  }catch(err){
      toast.error("Error while Deleting Slot!")
      console.log(err)
  }finally{
      setDeleting(false)
      getSlots()
  }
};


const handleUpdate = (index) => {
  setSlot(index);
  setModalOpen(true)
};


const handleModelClose = ()=>{
  setModalOpen(false)
  setSlot(null)
  getSlots()
}
const handleDeleteBtn = (slotid)=>{
  setSlotId(slotid)
  setDeleting(true)
}


 
 const [current, setcurrent] = useState(0);
 const prevSlide =()=>{

  const isFirstSlide = current === 0;
  const newIndex = isFirstSlide ? ground?.imgUrls?.length - 1 : current - 1;
     setcurrent(newIndex)
 }

 const nextSlide =()=>{

  const isLastSlide = current === ground?.imgUrls?.length - 1;
  const newIndex = isLastSlide ? 0 : current + 1;
     setcurrent(newIndex)
 }

 const gotoSlide = (slideIndex) =>{
  setcurrent(slideIndex);
 }


const [wait, setWait]= useState(false)
setTimeout(() =>{
    setWait(true)
},3000)


if(loading){
  return(
    <Loading />
  )
}

  return (
    <>
    <Helmet>
      <title>My Ground</title>
      <meta name='description' content='Maintain your ground and manage bookings'></meta>
      <link rel='canonical' href='/myground'/>
    </Helmet>
        {loading ?  <div className="flex justify-center items-center text-lg font-semibold h-screen"> <svg className=" border-4 border-gray-900 rounded animate-spin h-6 w-6 mr-3 ..." ></svg> Loading...</div>
        : 
        <>
        <div className="w-full text-black bg-slate-50 px-10 pt-[60px]">
        <div className="bg-[#008B0E] py-16">
                <div className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-6 mr-6 text-gray-200">WellCome to <span className="text-4xl sm:text-5xl lg:text-6xl text-white  font-bold">{ground?.name}</span></div>
                <div className="text-xs sm:text-sm text-center text-gray-300">Here you can Enjoy the game with you friends. So, Book your slot and Enjoy the Thrilling experience</div>
        </div>
          
          <div className=" relative group w-full mt-10 h-[600px] overflow-hidden">
            
            <div className="w-full h-full bg-center bg-cover group-hover:scale-110 transition-transform duration-500 rounded"
            // autoSlide={true} autoSlideInterval = {3000} 
            style={{backgroundImage:`url(${ground?.photos})` }}
            >
            </div>
            <div className="hidden z-10 group-hover:flex absolute top-[45%]  justify-between w-full">
              <button className="flex justify-center items-center  bg-[#008B0E] hover:bg-green-400 w-12 h-12 rounded-full" onClick={prevSlide}><img className="mr-1 h-6 w-6" alt="" src={left}/></button>
              <button className="flex justify-center items-center bg-[#008B0E] hover:bg-green-400 w-12 h-12 rounded-full" onClick={nextSlide}><img className="ml-1 h-6 w-6" alt="" src={right}/></button>
            </div>
            <div className="absolute inset-0 h-[40%] top-[60%] bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-transparent group-hover:via-black/60 group-hover:to-black/70  transition-transform duration-100"></div>
            <div  className='absolute inset-0 flex justify-center items-center translate-y-[45%] group-hover:translate-y-[28%] transition-all duration-500 w-full text-white font-bold '>
              <div className=' italic text-end'>
                  <div className="text-[80px]" >{ground?.name}</div>   
                  <div className=" text-xl opacity-0 group-hover:opacity-100">{ground?.aside }-a-side</div>
                  <div className="text-xl opacity-0 group-hover:opacity-100">{ground?.contact}</div>              
                  <div className="text-xl opacity-0 group-hover:opacity-100">{ground?.address}</div>
              </div>
            </div>   
            </div>
        </div>
        <div className="py-40  w-full bg-slate-50 px-16 text-black">
            <div className="grid justify-normal ">
              <div className="text-center text-lg sm:text-xl lg:text-6xl font-bold text-[#008B0E]">Booking</div>
                  
              <div className="px-3 my-10">
                <div className="grid grid-cols-7 mt-4 text-white gap-1">    
                    <button onClick={()=>handleSlots("Sat")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Sat"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Sat
                    </button>
                    <button onClick={()=>handleSlots("Sun")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Sun"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Sun
                    </button>
                    <button onClick={()=>handleSlots("Mon")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Mon"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Mon
                    </button>
                    <button onClick={()=>handleSlots("Tue")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Tue"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Tue
                    </button>
                    <button onClick={()=>handleSlots("Wed")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Wed"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Wed
                    </button>
                    <button onClick={()=>handleSlots("Thu")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Thu"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Thur
                    </button>
                    <button onClick={()=>handleSlots("Fri")} className={`py-3 rounded text sm:text-lg font-medium border-2 border-[#008B0E] ${day==="Fri"? " bg-[#008B0E]": "text-[#008B0E]"}`}> 
                        Fri
                    </button>
                </div>
                <div className='grid gap-3 mt-10'>
                <div className='flex justify-between gap-3 px-8 text-black font-medium'>
                    <div className='flex justify-between sm:gap-10 lg:gap-44 '>
                        <div className=''>Slot</div>
                        <div className=''>Time</div>      
                        <div className=' '>Price</div>
                    </div>
                    <div className='pr-8 md:pr-20'>Status</div>
                </div>
                {slotloading ? <div className="flex justify-center w-full my-10 text-lg font-semibold"><svg className=" border-4 border-black rounded animate-spin h-6 w-6 mr-2" ></svg> Loading...</div>  :
                <>
                <div>{
                        slots?.map((slot, index)=>(
                                <div key={index} className='mt-1  flex justify-between px-10 border border-[#008B0E] text-sm sm:text-base hover:border-green-500 rounded'>
                                    <div className='flex justify-between sm:gap-10 lg:gap-40 my-3'>
                                        <div className=''>{index + 1}</div>
                                        <div className=' '>{slot.timefrom}-{slot.timeto}</div> 
                                        <div className=''>Rs.{slot.price}</div>             
                                    </div>
                                    
                                    <div className='flex gap-16 lg:pr-12'>
                                        {
                                            day ? 
                                            <div className='flex items-center gap-2 md:gap-16  '>
                                                <button onClick={()=>handleDeleteBtn(slot._id)} className='group flex' >
                                                    <label className='hidden -mt-6 -ml-3 absolute group-hover:flex py-1 px-2 bg-gray-200  text-gray-800 text-xs rounded opacity-80'>delete</label>
                                                    <img className='h-[22px] w-[22px] sm:h-7 sm:w-7' src={deleteicon} alt=''/>
                                                </button> 
                                                <button onClick={() => handleUpdate(slot)} className='group flex '>
                                                    <label className='hidden absolute -mt-6 -ml-3 group-hover:flex py-1 px-2 bg-gray-200  text-gray-800 text-xs rounded opacity-80'>update</label>
                                                    <img className='h-[22px] w-[22px] sm:h-7 sm:w-7 ' src={updateicon} alt=''/>
                                                </button>  
                                            </div> 
                                        :
                                        <div></div>
                                        }  
                                        { 
                                                slot.status === "Available" ? <div className=' text-red-600 font-medium flex items-center sm:text-sm'>Not Booked</div> :  slot.status === "Booked" ? 
                                                <div className='flex items-center'>
                                                    {
                                                        ((slot.bookedby !== undefined)&&(slot.bookedby !== null)) ?  
                                                        <Link to={'/playerdetail/'+ slot.bookedby} className='flex items-center text-xs text-gray-600 hover:text-black underline'>booked by</Link> : <></>
                                                    }        
                                                </div>
                                                : <div className=' text-sm flex items-center justify-center text-gray-800 font-semibold'>{slot.status}</div> 
                                        }            
                                    </div>
                                
                                </div>
                                
                            ))
                    }
                    {
                        day  && (
                            <div className='flex justify-between mt-10 text-white'>
                                <div></div>
                                <button onClick={()=>{setModalOpen(true)}} className='bg-sky-600 hover:bg-sky-500  px-4 rounded font-medium text-sm py-1 sm:py-2'>Add Slot</button> 
                            </div>
                        )
                    }
                    {
                        modalOpen && (
                            <div className="flex justify-end">
                                <button className="h-10 w-10 mt-10 bg-red-600 rounded z-20 fixed inset-6 text-white  flex justify-center items-center" onClick={handleModelClose}>X</button>
                                <SlotModal onSubmit={handleModel} day={day} groundId={user?.groundId} slot ={slot} />
                            </div> 
                        )
                    }
                    </div>
                </>}
              
                </div>
            </div>
          
            </div>
            <div className='flex justify-between bg-[#008B0E] p-10  text-white mt-20'>
            
            <div className="grid justify-center italic items-center">
              
              <div className="text-lg  ">These are the rates of this ground. You can book your slot in advance.<br/> <br/>Weekdays:</div>
                <div className="flex gap-4 pl-10">
                  <div className=" text-gray-200 text-xs sm:text-sm ">Morning : </div>
                  <div className="text-xs sm:text-sm lg:text-base font-medium"> Rs.{ground?.weekdaymorning}</div>
                </div>
                <div className="flex gap-4 pl-10">
                  <div className="text-gray-200 text-xs sm:text-sm">Evening : </div>
                  <div className="text-xs sm:text-sm lg:text-base font-medium"> Rs.{ground?.weekdayevening}</div>
                </div>
              
                <div className="text-lg mt-">Weekend: </div>
                <div className="flex gap-4 pl-10">
                  <div className=" text-gray-200 text-xs sm:text-sm ">Morning : </div>
                  <div className="text-xs sm:text-sm lg:text-base font-medium"> Rs.{ground?.weekendmorning}</div>
                </div>
                <div className="flex gap-4 pl-10">
                  <div className="text-gray-200 text-xs sm:text-sm">Evening : </div>
                  <div className="text-xs sm:text-sm lg:text-base font-medium"> Rs.{ground?.weekendevening}</div>
                </div>

              <div>
              <div className="mt-10 text-lg sm:text-xl lg:text-2xl font-semibold ">For Query!</div>
              
                <div className="text-gray-200  ">For suggestions and complaints free free to contact us at <span className="text-white font-medium"> {ground?.email}</span> <br></br> or you can call us at <span className="text-white font-medium"> {ground?.contact}</span></div>
                <div className="mt-3 text-xs sm:text-sm lg:text-base">Regards from Ground Owner <span className="text-white font-medium"> {ground?.ownername} </span></div>
              </div>
            </div>
            <div>
              <img className="" alt="" src={location}/>
            </div>
            </div>
            
        </div>
        { wait===true && (
          <div className="w-full flex justify-end py-10 bg-slate-50 px-20">
              <Link to={"/groundgig"} className="text-xs sm:text-sm py-3 px-4 sm:px-6 shadow border-2 border-[#008B0E] hover:border-green-400 rounded text-[#008B0E] hover:text-green-400 font-medium">Edit Ground</Link>
          </div>
        ) }
          </>
        }
        {
        deleting &&
        (
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setDeleting(false)}} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this Slot?</h3>
                        <button onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button onClick={() =>{setDeleting(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>
        )}
    
   <ToastContainer />
    </>
  )
}

export default MyGround