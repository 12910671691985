import React from 'react'
import { Link } from "react-router-dom";
import defclub from '../images/defclub.png'

const FixtureCard = ({card}) => {


  return (
    <>
    <div className=' w-[280px] sm:w-[500px] md:w-[700px]  lg:w-[1000px]'>
        <div className='flex text-xs sm:text-sm'>
        <div className='text-gray-600 '>{card?.date},</div>
        <div className='text-gray-600 ml-6'>{card?.groundname}</div>
        <div className='text-gray-600 ml-6'>{card?.aside}v{card?.aside}</div>
        </div>
        <Link to={"/singlefixture/"+card._id} className='flex px-3 sm:px-20 lg:px-56 w-full justify-between items-center hover:scale-105 ease-in-out duration-700 rounded-full bg-white border shadow-lg py-2'>
        <div className='text-xs sm:text-sm lg:text-lg font-medium'>{card?.teamAname}</div>
        <div className='relative h-6 w-6 sm:h-10 sm:w-10 md:h-14 md:w-14 overflow-hidden rounded'>
            <img className='absolute h-full w-full object-cover' src={card?.teamAlogo} alt=''/>
        </div>
        <div className='grid justify-center text-center'>
          {
            card?.result && (
              <div className='flex whitespace-nowrap font-medium text-sm sm:text-xl text-center'>{card?.finalscore?.teamAscore} - {card?.finalscore?.teamBscore}</div>
            )
          }
          <div className='text-[10px] sm:text-xs lg:text-sm text-center mx-auto'>{card?.time}</div>
        </div>
        <div className='relative h-6 w-6 sm:h-10 sm:w-10 md:h-14 md:w-14 overflow-hidden rounded'>
            <img className='absolute h-full w-full object-cover' src={card?.teamBlogo || defclub} alt=''/>
        </div>
        <div className='text-xs sm:text-sm lg:text-lg font-medium'>{card?.teamBname || "TBD"}</div>
        </Link>
    </div>
    </>
  )
}

export default FixtureCard