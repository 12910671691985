import { initializeApp, getApp, getApps } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



const firebaseConfig = {
  apiKey: process.env.React_App_API_KEY,
  authDomain: process.env.React_App_AUTH_DOMAIN,
  projectId: process.env.React_App_PROJECT_ID,
  storageBucket: process.env.React_App_STORAGE_BUCKET,
  messagingSenderId: process.env.React_App_SENDER_ID,
  appId: process.env.React_App_APP_ID
};

if(getApps().length === 0){
    initializeApp(firebaseConfig);
}

const fbApp = getApp();
const imagedb = getStorage()

/**
 * 
 * @param {*} uri 
 * @param {*} name 
 */

const uploadToFireBase = async(uri, name, folder, onProgress)=>{

  const fetchResponse = await fetch(uri);
  const theBolb = await fetchResponse.blob()

  const imageRef = ref(getStorage(), `${folder}/${name}`);
  const uploadTask = uploadBytesResumable(imageRef, theBolb);

  return new Promise((resolve, reject)=> {
  uploadTask.on('state_changed', 
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress && onProgress(progress);
    }, 
    (error) => {
      // Handle unsuccessful uploads
      reject(error)
    }, 
    async() => {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
      resolve({
        downloadUrl,
        metadta: uploadTask.snapshot.metadata
      })
      });
    }
  );
}

export {
    fbApp,
    imagedb,
    uploadToFireBase
}
