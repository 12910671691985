import React, {useState, useEffect} from 'react'
import star from '../images/star.png'
import axios from 'axios';
import { Link } from "react-router-dom";


const GroundCard = () => {

    const [grounds, setGrounds] = useState([]);
    const[loading, setLoading] = useState(false)

    useEffect(()=>{
        const getGrounds = async() =>{
            setLoading(true)
            try{
                const res = await axios.get(`${process.env.React_App_Server}/grounds/grounds`)
                setGrounds(res.data.grounds)
            }catch(e){
                console.log(e);
            }finally{
                setLoading(false)
            }
        }

        getGrounds();
    },[])

  return (
    <>
    {
        loading ?
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
       :
        <div className='flex flex-wrap justify-center sm:justify-start gap-10 '>
            {grounds?.map((card, index) =>(
                <Link to={'/singleground/' + card?._id} key={index} className='relative w-[250px] hover:scale-110 duration-300 hover:ease-in-out  border-2 text-neutral-900 rounded-xl cursor-pointer shadow-xl'>
                    <img className=' w-full h-[180px] rounded-t-xl object-cover' src={card?.photos} alt='ground'/>
                    <div className='flex justify-between px-6 mt-3 w-full'>
                        <div className='text-neutral-900 text-xl font-bold'>{card?.name}</div>
                        <div className='text-sm mt-2 font-medium'><span className='text-xs text-gray-800'>Rs.</span>{card?.weekdayevening}</div>    
                    </div>
                    <div className='flex justify-between px-6 mt-2'>
                        <div className='text-xs whitespace-nowrap'>{card?.address}/{card?.city}</div>
                        <div className='flex justify-end w-full text-xs' ><img className='w-[16px] h-[16px] mr-2' src={star} alt=''/>(4/5)</div>
                    </div>
                    <div className=' px-6 mb-3 mt-4 '>
                        <button className='py-1 w-full text-white hover:text-neutral-800 bg-neutral-800 hover:bg-white border-2 hover:ease-in-out duration-300 border-neutral-800  font-semibold rounded-full shadow shadow-neutral-600'>
                            Book
                        </button>
                    </div>
                </Link>
            ))}
        </div>
    }
        
    </>
  )
}

export default GroundCard