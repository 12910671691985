import React from "react";
import googleplay from "../images/googleplay.webp"
import appstore from "../images/appstore.webp"
import fb from "../images/facebook.png"
import insta from "../images/instagram.png"
import logo from '../images/whitelogo.png';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bg-black h-screen text-white">
        <div className="h-[95%] grid justify-center gap-10 md:gap-6 lg:gap-2 grid-cols-1 sm: sm:grid-cols-2 lg:grid-cols-4  border-t-2 py-3 sm:py-16 lg:py-32">      
          <div className="flex justify-center text-4xl font-semibold gap-2">
              <img className="h-16 sm:h-24 lg:h-36 " src={logo} alt=""/>
          </div>
          <div className="grid text-center sm:text-start px-5">
            <div>
              <div className="text-xl sm:text-2xl lg:text-3xl font-semibold">Inspiration</div>
              <div className="flex justify-center text-[10px] sm:text-xs lg:text-sm mt-3">
                Good News for Football fans! Are you are tired of not finding the teams to play against or
                unable to keep track of your Journey or your Team Accomplishments. Now we provide 
                the solution. So, lets get started and keep exploring on the way.
              </div>
            </div>
            <div>
              <Link to={"/privacypolicy"} className="text-2xl font-medium">Privacy Policy</Link>
            </div>
          </div>
        
          <div className="grid justify-center  text-sm px-5">  
            <div className="text-xl sm:text-2xl lg:text-3xl font-semibold">Download the App</div>
            <div className="flex justify-center">
              <a rel="noreferrer" target='_blank' href='https://play.google.com'><img className="rounded-xl h-6 sm:h-10 lg:h-14" src={googleplay} alt=""/></a>
            </div>
            <div className="flex justify-center">
              <a rel="noreferrer" target='_blank' href='https://apps.apple.com'><img className="rounded-xl h-6 sm:h-10 lg:h-14" src={appstore} alt=""/></a>
            </div>  
          </div>
          <div className="grid justify-center sm:justify-start px-5">
            <div className="text-xl sm:text-2xl lg:text-3xl font-semibold">Follow US</div>
            <div className="flex justify-center">
              <a rel="noreferrer" target='_blank' href='https://www.facebook.com'> <img className="rounded-xl h-5 lg:h-10" src={fb} alt=""/></a>
            </div>
            <div className="flex justify-center">
              <a rel="noreferrer" target='_blank' href='https://www.instagram.com'> <img className="rounded-xl h-5 lg:h-10" src={insta} alt=""/></a>
            </div>
            
           
          </div>
        </div>
        <div className=" flex justify-around text-[8px] sm:text-[10px] py-3 border-t-2 text-gray-300 backdrop-brightness-50 backdrop-blur-[12px]">
              <div>© 2024 OrKhylo.</div>
              <div>Design & Developed By Marhaba Codes</div>
        </div>
      </div>
    </>
    
  );
}

export default Footer;
