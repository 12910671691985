import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import herovideo from "../images/herovideo.mp4"
import playercareer from "../images/playercareer.webp"
import manageteam from "../images/manageteam.webp"
import bookground from "../images/bookground.webp"
import footballmatch from "../images/footballmatch.webp"
import scoutplayer from "../images/scoutplayer.webp"
import jointeam from "../images/jointeam.webp"
import footballbg from "../images/footballbg.webp"
import blackbg from '../images/blackbg.webp'
import redbg from '../images/redbg.webp'
import greenbg from '../images/greenbg.webp'
import bluebg from '../images/bluebg.webp'
import tacklegif from '../images/tackle.webp'
import playergif from '../images/player.webp'
import teamgif from '../images/team.webp'
import lineupgif from '../images/lineup.webp'
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from "recoil";
import userAtom from "../atoms/userAtom";
import { Helmet } from "react-helmet-async";

//rafce
function Home(){

    const user = useRecoilValue(userAtom);
    const[index, setIndex] = useState(0)

    let hero =[
        {
            text:"Book Grounds & Play Matches",
            img:lineupgif,
            bg:bluebg,
            theme:0
        },
        {
            text:"Join Teams or Become Manager",
            img:teamgif,
            bg:greenbg,
            theme:1
        },
        {
            text:"Play Matches & Push Career",
            img:tacklegif,
            bg:redbg,
            theme:2
        },
        {
            text:"Kickoff your Career",
            img: playergif,
            bg:blackbg,
            theme:3
        },
    ]

    useEffect(()=>{
        setTimeout(()=>{
            if(index === 3){
                setIndex(0)
            }else{
                let newindex = index +1;
                setIndex(newindex)
            }
        },5000)
    },[index])

    return (
        <>
        <Helmet>
            <title>OrKhylo</title>
            <meta name="description" content="Book football grounds, join teams, manage teams, and play matches with OrKhylo."></meta>
            <link rel='canonical' href='/'/>
        </Helmet>
        <motion.div
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1}}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className="h-[580px] lg:h-screen pb-1 ease-in-out duration-700 overflow-hidden flex justify-center"
        >
            <div className="grid sm:grid-cols-5 h-[100%] bg-opacity-0 bg-no-repeat justify-center">
                    <div className="hidden lg:flex absolute h-full overflow-hidden">
                        <img className="" src={footballbg} alt=""/>
                    </div>
                <motion.div
                     key={index}
                     initial={{ opacity: 0, x: -50 }}
                     animate={{ opacity: 1, x: 0 }}
                     exit={{ opacity: 0, x: 50 }}
                     transition={{ duration: 1 }}
                    className={`relative z-10 order-2 w-full sm:order-first sm:col-span-2 ${hero[index]?.theme === 0 ? "text-[#073F93]" : hero[index]?.theme === 1 ? "text-[#1E6D0A]": hero[index]?.theme === 2 ? "text-[#F03737]" : hero[index]?.theme === 1 ? "text-[#272323]" : ""} grid justify-center items-center sm:h-[60%] md:h-[70%] -mt-[5%] sm:mt-[45%] md:mt-[25%] lg:mt-[20%] lg:h-[70%] text-center sm:pl-10 lg:pl-20 `} >
                   
                    <div className="hidden lg:flex font-bold text-sm sm:text-base  ">
                        Welcome To OrKhylo
                    </div>
                    <div className="text-xl sm:text-3xl lg:text-6xl flex justify-center text-center sm:text-start font-extrabold tracking-widest">
                        {hero[index]?.text}
                    </div>
                    <div className="hidden sm:flex w-[90%] text-center sm:text-end tracking-widest mt-3 font-bold text-[10px] sm:text-xs lg:text-lg">
                        Everything more than just OK
                    </div>
                    <div className="mb-5 sm:mb-0 sm:mt-10 flex justify-center sm:justify-start">
                        <a rel="noreferrer" target='_blank' href='https://play.google.com' className={`h-[32px] sm:h-full py-1 sm:py-2 ${hero[index]?.theme === 0 ? "border-[#073F93] bg-[#073F93] hover:text-[#073F93]" : hero[index]?.theme === 1 ? "border-[#1E6D0A] bg-[#1E6D0A] hover:text-[#1E6D0A]": hero[index]?.theme === 2 ? "border-[#F03737] bg-[#F03737] hover:text-[#F03737]" : hero[index]?.theme === 3 ? "border-neutral-800 bg-neutral-800 hover:text-[#272323]" : ""} md:py-4 px-3 sm:px-5 md:px-10 rounded-lg    border-2 hover:bg-white duration-300 text-white font-semibold text-xs pt-1.5 md:pt-4 sm:text-base md:text-lg `}>Go to App</a>
                    </div>
                </motion.div> 
                <div className="  sm:col-span-3 flex justify-center sm:justify-end ">
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 1 }}
                        className="relative h-[95%] w-[95%] sm:w-[90%] sm:h-[90%] sm:pr-5 lg:pr-10 flex justify-center sm:mt-[5%]"
                        >
                        <img className="h-full " src={hero[index]?.img} alt="hero"/>
                    </motion.div>
                    <div
                     
                        className="absolute right-0"
                        >
                        <img className="h-[250px] sm:h-[350px] lg:h-[500px]" src={hero[index]?.bg} alt=""/>
                    </div>
                </div>
            </div>
        </motion.div>
            <div className="bg-neutral-900 h-10 sm:h-20 lg:h-40 w-full"></div>
            <div className="w-full h-[600px] sm:h-[800px] lg:h-screen overflow-hidden flex justify-center lg:py-10 px-5 sm:px-10 lg:px-20 bg-neutral-900 ">
                <div className="grid lg:flex justify-center gap-3 w-full">
                    <div className="lg:w-[55%] h-full rounded-tl-3xl lg:rounded-l-3xl space-y-2 overflow-hidden">
                        <div className="h-full relative overflow-hidden rounded-tl-3xl lg:rounded-l-3xl">
                            <video className="absolute h-full w-full object-cover  transition ease-in-out delay-50 hover:scale-105  duration-700" controls={false} autoPlay loop muted>
                                <source src={herovideo} type="video/mp4"></source>
                            </video >
                            <div className="relative h-full w-full animate-pulse hover:animate-none  backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-3xl sm:text-5xl pl-5 pb-8">Football The Beautiful Game.</div>  
                        </div>    
                        
                    </div>
                    <div className="lg:w-[45%] lg:rounded-r-3xl space-y-3 overflow-hidden">
                        <div className="relative h-[57%] lg:rounded-tr-3xl hover:scale-105 ease-in-out duration-500">                    
                            <img className="absolute h-full w-full object-cover lg:rounded-tr-3xl" src={bookground} alt=""/>
                            <Link to={"/grounds"} className="relative h-full w-full animate-pulse hover:animate-none  backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-3xl sm:text-5xl pl-5 pb-8">Book Grounds</Link>                    
                        </div>
                        <div className="h-[41%] rounded-br-3xl grid grid-cols-2 gap-3 overflow-hidden">
                            <div className="col-span-1 relative backdrop-blur-md backdrop-brightness-150 hover:scale-105 ease-in-out duration-500">
                                <img className="absolute h-full w-full object-cover" src={manageteam} alt=""/>
                                <Link  to={user?.teamId ? "/myteam/"+user?.teamId : "/login"} className="relative h-full animate-pulse hover:animate-none backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-lg sm:text-3xl pl-5 pb-8">Manage Team</Link>
                            </div>
                            <div className="col-span-1 relative  backdrop-blur-md backdrop-brightness-150 overflow-hidden hover:scale-105 ease-in-out duration-500">
                                <img className="absolute h-full w-full object-cover" src={playercareer} alt=""/>
                                <Link to={user?._id ? "/playerdetail/"+user?._id : "/login"} className="relative h-full animate-pulse hover:animate-none  backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-lg sm:text-3xl pl-5 pb-8">Player Career</Link>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div className="bg-neutral-900 h-10 sm:h-20 lg:h-40 w-full"></div>
            <div className="bg-white h-10 sm:h-20 lg:h-40 w-full"></div>
            <div className=" h-[600px] sm:h-screen grid sm:grid-cols-3 gap-3 overflow-hidden w-full mx-auto lg:py-10 px-5 sm:px-10 lg:px-20  rounded-tl-3xl ">
                <div 
                 className="col-span-1 relative  backdrop-blur-md hover:scale-105 ease-in-out duration-500 backdrop-brightness-150 rounded-tl-3xl overflow-hidden">
                    <img className="absolute h-full w-full object-cover" src={jointeam} alt="Teams"/>
                    <Link to={"/teams"} className="relative h-full animate-pulse hover:animate-none backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-3xl lg:text-6xl pl-5 pb-8">Join Team</Link>
                </div>
                <div 
                 className="col-span-1 relative backdrop-blur-md hover:scale-105 ease-in-out duration-500 backdrop-brightness-150">
                    <img className="absolute object-cover h-full w-full" src={scoutplayer} alt="Players"/>
                    <Link to={"/players"} className="relative h-full animate-pulse hover:animate-none  backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-3xl lg:text-6xl pl-5 pb-8">Scout Players</Link>
                </div>
                <div 
                 className="col-span-1 relative backdrop-blur-md hover:scale-105 ease-in-out duration-500 backdrop-brightness-150 rounded-br-3xl overflow-hidden">
                    <img className="absolute object-cover h-full w-full" src={footballmatch} alt="Fixtures"/>
                    <Link to={"/fixtures"} className="relative h-full animate-pulse hover:animate-none  backdrop-blur-sm backdrop-brightness-50 flex items-end text-white font-semibold text-3xl lg:text-6xl pl-5 pb-8">Fixtures</Link>
                </div>
            </div>
            <div className="bg-white h-10 sm:h-20 lg:h-40 w-full"></div>
        </>
    )
}

export default Home;