import React, { useEffect, useRef, useState } from "react";
import menu from '../images/icons8-menu-50.png'
import logo from '../images/blacklogo.png';
import dropdown from '../images/dropdown.png'
import { useRecoilState, useSetRecoilState } from "recoil";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from "react-router-dom";
import groundAtom from "../atoms/groundAtom";
import logout from "../images/logout.png"
import axios from 'axios';
import userAtom from "../atoms/userAtom";


const Navbar = () => {
  
  let menuRef = useRef()
  const location = useLocation()
  const [toggle, setToggle] = useState(false);
  const [option, setOption] = useState(false);
  const [user, setUser] = useRecoilState(userAtom);
  const setGround = useSetRecoilState(groundAtom);



  const handleLogout = async ()=> {
    try{
      localStorage.removeItem("user-threads");
      localStorage.removeItem("jwtToken");
      localStorage.clear();
      setUser(null);
      setGround(null)
      toast.info("Logged Out");
      window.open(`${process.env.React_App_Server}/users/logout`, "_self")
    }catch(e){
      console.log(e)
      toast.error("Error while Logout")
    }
  }

  const getUser=async()=>{
    try {
      const res = await axios.get(`${process.env.React_App_Server}/users/user?_id=${user?._id}`)
      setUser(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    if(user){
      getUser()
    }
  },[])
  
  const pathMatch = (route)=>{
    if(route === location.pathname){
        return true
    }
  }

  const closeMenuOnOutsideClick = (e) => {
    if (!menuRef.current || !menuRef.current.contains(e.target)) {
      setOption(false);
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeMenuOnOutsideClick);
    };
  }, []);

  return ( 
      <div className=" text-black fixed w-full z-50">
        <header className="h-[30px] sm:h-[45px] lg:h-[60px] mt-2 mx-5 rounded-full  flex items-center justify-between  bg-white/20  backdrop-blur-[8px] px-4 sm:px-8 ">   
    
            <div className="flex-shrink-0 sm:w-[25%] lg:w-[40%] flex justify-center">  
                <Link to={"/"}>
                  <img className="h-[25px] sm:h-[40px] lg:h-[52px] md:mr-2" src={logo} alt=""/>
                </Link>      
            </div>
            <div className="hidden md:flex">
              <div className="ml-4 flex gap-3 sm:gap-6 lg:gap-10">
                <Link to={"/fixtures"}><span className={`${pathMatch("/fixtures") ? " border-b-2 border-gray-600" : ""} hover:border-b-2 hover:border-gray-400 px-1 py-1 text-sm font-medium`}>Fixtures</span></Link>
                <Link to={"/grounds"}><span className={`${pathMatch("/grounds") ? " border-b-2 border-gray-600" : ""} hover:border-b-2 hover:border-gray-400 px-1 py-1 text-sm font-medium`}>Grounds</span></Link>
                <Link to={"/teams"}><span className={`${pathMatch("/teams") ? " border-b-2 border-gray-600" : ""} hover:border-b-2 hover:border-gray-400 px-1 py-1 text-sm font-medium`}>Teams</span></Link>
                <Link to={"/players"}><span className={`${pathMatch("/players") ? " border-b-2 border-gray-600" : ""} hover:border-b-2 hover:border-gray-400 px-1 py-1 text-sm font-medium`}>Players</span></Link>
                { user
                ?
                <>
                <div  ref={menuRef}>
                  <div className="flex">
                    <button onClick={()=>{setOption(!option)}} className="flex ml-4 w-[110px]  text-white hover:bg-neutral-800 bg-neutral-600 px-3 py-2 -mt-1 rounded text-sm font-medium"><span className="whitespace-nowrap truncate">{user?.username}</span> <img className="absolute ml-20 h-4 w-4 mt-0.5 " src={dropdown} alt=""/></button>      
                  </div>
                  
                  {
                    option ?
                    <div ref={menuRef} className="absolute text-start font-medium right-[32px] bg-neutral-800 text-gray-300 px-10 gap-4 py-6 rounded-lg top-[50px] z-20 grid grid-rows-2">
                      <Link to="/mybooking" className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-700 px-3">Booked Slots</Link>
                      <Link to="/myground" className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">{user?.groundId ? <>Manage Ground</> : <>Create Ground</> } </Link>
                      <Link to={"/singleteam/"+ user?.gig?.myteam?.toString()} className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">{user?.gig?.myteam ? <>My Team</> : <>Join Team</> }</Link>
                      <Link to={"/requests/"+user._id} className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">Requests</Link>
                      {
                        user?.teamId ?
                        <Link to={"/myteam/"+ user?.teamId} className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">Manage Team </Link>
                        :
                        <Link to={"/teamgig"} className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">Create Team </Link>
                      }
                      <Link to={"/playerdetail/"+user?._id} className="row-span-1 border-b-2 hover:text-white hover:bg-neutral-600 px-3">Profile</Link>
                      <button onClick={handleLogout} className="flex justify-center gap-2 hover:text-white hover:bg-neutral-600">Logout<img className="h-4 w-4 mt-1" src={logout} alt=""/></button>
                    </div> : <></>
                  }
                  </div>
                  
                </>
                :
                <>
                <Link to={"/login"}>
                  <span className="ml-4 border-2 bg-white hover:bg-gray-400 text-gray-600 hover:text-white px-6 py-3 rounded text-sm font-medium">Login </span>
                </Link> 
                </>
                }
                </div>  
            </div>
              <span ref={menuRef} onClick={() => setToggle(!toggle)} className="md:hidden">
                {toggle ? (
                  <img className="sm:h-8 h-6 w-6 sm:w-8 px-1 bg-gray-300 rounded" alt="" src={menu}/>
                ) : (
                  <img className="sm:h-8 h-6 w-6 sm:w-8 px-1 bg-gray-300 rounded" src={menu} alt="" />
                )}
              </span>
        {
          toggle && (
          <div ref={menuRef} className="z-20  absolute  top-[40px] sm:top-[60px] left-[10px] rounded-lg bg-neutral-800">
              <div className=" grid space-y-1 sm:px-3 py-1">
                <Link to={'/fixtures'}><span className="text-white hover:text-black block px-3 py-1 rounded-md  text-xs sm:text-sm font-medium">Fixtures</span></Link>
                <Link to={"/grounds"}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Grounds</span></Link>
                <Link to={'/teams'}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Teams</span></Link>
                <Link to={'/players'}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Players</span></Link>
                <Link to={'/singleteam/'+ user?.gig?.myteam?.toString()}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">My Team</span></Link>
                {
                  user?.teamId ?
                  <Link to={'/myteam/'+user?.teamId}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Manage Team</span></Link>
                  :
                  <Link to={'/teamgig'}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Create Team</span></Link>
                }
                <Link to={'/playerdetail/'+user?._id}><span className="text-white hover:text-black block px-3 py-1 rounded-md text-xs sm:text-sm font-medium">Profile</span></Link>
              </div>
            <div className="px-4 py-3 border-t border-gray-700">
              <button onClick={handleLogout} className="flex justify-center gap-2 text-white font-semibold bg-gray-500 px-3 py-2 rounded text-sm">Logout<img className="h-3 w-3 mt-1" src={logout} alt=""/></button>
            </div>
          </div>
          )
        }
    </header>
    <ToastContainer />
    </div>
  );
}
export default Navbar