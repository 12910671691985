import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import defclub from '../images/defclub.png'



const PlayerCard = () => {

    const [players, setPlayers] = useState([]);
    const[loading, setLoading] = useState(false)
    
    useEffect(()=>{
        const getPlayers = async() =>{
            setLoading(true)
            try{
                const res = await axios.get(`${process.env.React_App_Server}/users/all`);
                setPlayers(res.data.users)
            }catch(e){
                console.log(e);
            }finally{
                setLoading(false)
            }
        }
    
        getPlayers();
    },[])

  return (
    <>
    {loading ? 
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
    <div className='flex flex-wrap justify-center sm:justify-start gap-3 sm:gap-7 '>
        {players?.map((player, index) =>(
            <Link className=" w-[160px]  hover:scale-110 duration-300 hover:ease-in-out  border text-neutral-900 rounded-xl cursor-pointer shadow-xl" to={'/playerdetail/' + player._id} key={index}>
                <div className='relative w-full h-[140px] flex justify-center'>
                    <img className='h-full w-full object-cover rounded-t-xl' src={player?.profilePic} alt='ground'/>
                </div>
                <div className='absolute ml-[65px] -mt-6 z-20 bg-white p-1 rounded-full'>
                    <img className='h-6 w-6 rounded' src={player?.gig?.teamlogo || defclub} alt=""/>
                </div>
             
                <div className='flex  text-sm font-medium px-3 mt-3 w-full'>
                    <div className='whitespace-nowrap truncate text-black'>{player?.username}</div> 
                </div>
                <div className='flex justify-between text-xs px-3 mb-1'>
                    <div className=' whitespace-nowrap mt-1 text-neutral-900'><span className='text-[10px] text-gray-500'>aside: </span> {player?.gig?.aside || "NA"}</div> 
                    <div className=' whitespace-nowrap mt-1 text-neutral-900'><span className='text-[10px] text-gray-500'>pos: </span> {player?.gig?.position || "NA"}</div> 
                </div>
            </Link>
        ))}
    </div>
}
</>
  )
}

export default PlayerCard