import React from 'react'
import { TeamCard } from '../../components';
import { Helmet } from 'react-helmet-async';

const Teams = () => {

  return (
    <>
    <Helmet>
      <title>Teams</title>
      <meta name='description' content='Join the Best Team and Play against Best Teams'></meta>
      <link rel='canonical' href='/teams'/>
    </Helmet>
    <div className="grid w-full py-14 sm:py-20 lg:py-32 px-3 sm:px-10 lg:px-20 bg-slate-50 text-neutral-900 min-h-[500px] sm:min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-xl sm:text-4xl lg:text-6xl">Teams</div>
            <div className="flex w-full justify-center text-center text-xs sm:text-sm overflow-hidden mt-2 px-3">
                Join the best teams and push your career to next level.
            </div>
        </div>
        <div className="sm:my-10 flex justify-center sm:justify-start">
            <TeamCard />
        </div>
    </div>
    </>
  )
}

export default Teams