import React from 'react'
import createground from "../../images/createground.webp"
import emaillogo from '../../images/emaillogo.png'
import phonelogo from '../../images/phonelogo.png'
import { Helmet } from 'react-helmet-async'

const CreateGround = () => {
  return (
    <>
    <Helmet>
      <title>Create Ground</title>
      <meta name='description' content='Become Partners with OrKhylo'></meta>
      <link rel='canonical' href='/createground'/>
    </Helmet>
    <div className='grid grid-cols-3 min-h-screen p-20 justify-center items-center'>
        <div className='col-span-2 relative h-full w-full overflow-hidden rounded-lg pt-10'>
            <img className='absolute h-full w-full object-cover rounded-lg' src={createground} alt='' />
        </div>
        <div className='col-span-1 flex justify-center items-center w-full'>
            <div className='h-[40%] grid justify-center items-center'>
                <div className='text-2xl font-medium'>Become Partners with OrKhylo</div>
                <div className='mt-3 text-gray-700 '>Contact us to Register your Ground</div>
                <div className='w-full flex gap-1 mt-3'>
                    <img className='h-6 ' src={emaillogo} alt='email'/>
                    <div className='font-medium'>orkhylo@gmail.com</div>
                </div>
                <div className='w-full flex gap-1 mt-1'>
                    <img className='h-5 ' src={phonelogo} alt='phone'/>
                    <div className='font-medium'>+92 333-1143330</div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CreateGround