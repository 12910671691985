import { Outlet, Navigate} from "react-router-dom"
import { useAuthStatus } from "../context/useAuthStatus"
import Loading from "./Loading"


 

export default function PrivateRoute(){
    

    const {loggedIn, status} = useAuthStatus()

    if(status){
        return <Loading />
    }
    return loggedIn ? <Outlet /> : <Navigate to="/login"/>
}