import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch';
import { useRecoilState } from 'recoil';
import teamAtom from '../../atoms/teamAtom';
import axios from 'axios';
import defclub from '../../images/defclub.png'
import fixtureAtom from '../../atoms/fixtureAtom';
import { Helmet } from 'react-helmet-async';


const TeamFixtures = () => {
    const {id} = useParams()
    const[team, setTeam]= useRecoilState(teamAtom)
    const[fixture, setFixture]= useRecoilState(fixtureAtom)
    const {data, loading, reFetch} = useFetch(`${process.env.React_App_Server}/fixtures/manage?teamId=${id}`)


    useEffect(()=>{
      const getTeam = async () => {
        try {
          const res = await axios.get(`${process.env.React_App_Server}/teams/team/${id}`)
          
          setTeam(res.data)
        } catch (error) {
          console.log(error);
        } 
      };
      getTeam()
    },[])

    useEffect(() =>{
      reFetch()
    },[])


  return (
    <>
    <Helmet>
      <title>Active Fixtures</title>
      <meta name='description' content='See your active matches'></meta>
      <link rel='canonical' href='/teamfixtures'/>
    </Helmet>
     <div className="grid w-full py-20 px-20 bg-slate-50 text-neutral-900 min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-6xl">My Team Fixtures</div>
            <div className="flex w-full justify-center text-center text-sm overflow-hidden mt-2">
                Challange the best team and push yourself to limit.
            </div>
        </div>
        <div className='flex gap-3 text-sm text-gray-500 mt-20 mb-3'>
          <div className='font-medium'>Note:</div>
          <div>submit the result of pending fixtures to free fixture slots</div>
        </div>
       {loading ? 
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
      <div className=" w-full pb-20 min-h-screen bg-clate-50 text-black">
        <div className='flex justify-between gap-10 font-medium w-full'> 
          <div className='flex gap-10 '>
           
            <div className="bg-neutral-800 px-6 rounded text-white py-2">Total Fixtures</div>
            <div className='text-2xl mt-1'>{data?.length}/5</div>
          </div>
          <div className='flex'>
            <Link onClick={()=>setFixture(null)} to={"/fixturegig/"+0} className='flex bg-green-600 px-6 py-2 rounded text-white'>Create Fixture</Link>
          </div>
        </div>
        <div className="mt-10 grid gap-10 justify-center w-full">
        { data && data?.map((card, index) =>(
                <div key={index} className='w-[1000px] '>
                    <div className='flex'>
                      <div className='text-gray-600  text-sm'>{card?.date},</div>
                      <div className='text-gray-600 ml-6 text-sm'>{card?.groundname}</div>
                      <div className='text-gray-600 ml-6 text-sm'>{card?.aside}v{card?.aside}</div>
                    </div>
                    <Link to={"/singlefixture/"+card._id} className='flex w-full justify-between items-center hover:scale-105 ease-in-out duration-700 rounded-full bg-white border shadow-lg py-2 px-56'>
                      <div className='text-lg font-medium'>{card?.teamAname}</div>
                      <div className='relative h-14 w-14 overflow-hidden rounded'>
                        <img className='absolute h-full w-full object-cover' src={card?.teamAlogo} />
                      </div>
                      <div>{card?.time}</div>
                      <div className='relative h-14 w-14 overflow-hidden rounded'>
                        <img className='absolute h-full w-full object-cover' src={card?.teamBlogo || defclub} />
                      </div>
                      <div className='text-lg font-medium'>{card?.teamBname || "TBD"}</div>
                    </Link>
                </div>
            ))}
        </div>
      </div>
      }
      </div>   
    </>
  )
}

export default TeamFixtures