import React from 'react'
import GroundCard from '../../components/GroundCard'
import { Helmet } from 'react-helmet-async'


const Grounds = () => {
  return (
    <>
    <Helmet>
      <title>Grounds</title>
      <meta name='description' content='Book the Ground near you'></meta>
      <link rel='canonical' href='/grounds'/>
    </Helmet>
    <div className="grid w-full py-14 sm:py-20 lg:py-32 px-3 sm:px-10 lg:px-20 bg-slate-50 text-neutral-900 min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-xl sm:text-4xl lg:text-6xl">Grounds</div>
            <div className="flex w-full justify-center text-center text-xs sm:text-sm overflow-hidden mt-2">
                Book Grounds easily and focus more on the Game.
            </div>
        </div>
        <div className="my-20 ">
            <GroundCard />
        </div>
    </div>
    </>
  )
}

export default Grounds