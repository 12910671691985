import React from 'react'
import { Helmet } from 'react-helmet-async'

const TermsOfService = () => {
  return (
    <>
      <Helmet>
            <title>OrKhylo  -Terms of Service</title>
            <meta name="description" content="OrKhylo Terms of Service"></meta>
            <link rel='canonical' href='/termsofservice'/>
        </Helmet>
    <div className='p-20 '>
        <div className='font-medium text-lg'>Terms of Service</div>
        <div className='text-sm mt-3'>Last Updated: 2/23/2024</div>
        <div className='font-medium text-lg mt-10'>Acceptance of Terms:</div>
        <div className='text-sm mt-3'>By accessing and using OrKhylo ("the Website"), you agree to comply with and be bound by the following Terms of Service ("ToS"). If you do not agree to these terms, please refrain from using the Website.</div>
        <div className='font-medium text-lg mt-10'>User Accounts:</div>
        <div className='text-sm mt-3'>
            •	To access certain features of the Website, you may be required to create a user account..<br></br>
            •	You are responsible for maintaining the confidentiality of your account credentials and are solely responsible for all activities that occur under your account.
        </div>
        <div className='font-medium text-lg mt-10'>User Conduct:</div>
        <div className='text-sm mt-3'>
            You agree not to:<br></br>
            •	Violate any applicable laws, regulations, or third-party rights.<br></br>
            •	Use the Website for any unlawful or unauthorized purpose.<br></br>
            •	Transmit any viruses, worms, or malicious code.<br></br>
            •	Harass, abuse, or harm other users.
        </div>
        <div className='font-medium text-lg mt-10'>Google OAuth and User Authentication:</div>
        <div className='text-sm mt-3'>
            •	User authentication is facilitated through Google OAuth. By using this feature, you agree to comply with Google's Terms of Service.<br></br>
            •	We do not store or have access to your Google account credentials.
        </div>
        <div className='font-medium text-lg mt-10'>Content and Intellectual Property:</div>
        <div className='text-sm mt-3'>
            •	Users are solely responsible for the content they submit to the Website.<br></br>
            •	By submitting content, you grant OrKhylo a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute the content.<br></br>
            •	The Website and its original content, features, and functionality are owned by sole proprietorship Muhammad Imran and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </div>
        <div className='font-medium text-lg mt-10'>Privacy:</div>
        <div className='text-sm mt-3'>
            •	Our Privacy Policy governs the collection and use of user data. By using the Website, you agree to the terms outlined in the Privacy Policy.
        </div>
        <div className='font-medium text-lg mt-10'>Termination:</div>
        <div className='text-sm mt-3'>
            •	We reserve the right to suspend or terminate your account and access to the Website at our sole discretion, with or without cause, and with or without notice.
        </div>
        <div className='font-medium text-lg mt-10'>Changes to Terms of Service:</div>
        <div className='text-sm mt-3'>
            •	OrKhylo reserves the right to update and change the ToS from time to time without notice. Continued use of the Website after any changes constitute your acceptance of such changes.
        </div>
        <div className='font-medium text-lg mt-10'>Disclaimer of Warranties:</div>
        <div className='text-sm mt-3'>
            •	The Website is provided "as is" and "as available" without any warranties of any kind, either express or implied.
        </div>
        <div className='font-medium text-lg mt-10'>Limitation of Liability:</div>
        <div className='text-sm mt-3'>
            •	OrKhylo shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
        </div>
        <div className='font-medium text-lg mt-10'>Governing Law:</div>
        <div className='text-sm mt-3'>
            •	These Terms of Service shall be governed by and construed in accordance with the laws of Pakistan, without regard to its conflict of law principles.
        </div>
        <div className='font-medium text-lg mt-10'>Contact Information:</div>
        <div className='text-sm mt-3'>
            If you have any questions regarding this privacy policy, please contact us at <br></br>
            orkhylo@gmail.com.
        </div>
    </div>
    </>
  )
}

export default TermsOfService