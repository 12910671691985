import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const MyBooking = () => {
    
    const user = useRecoilValue(userAtom)
    const [slots, setSlots] = useState([])
    const [loading, setLoading] = useState(false)
    
    const bookedSlots = async()=>{
        if(loading) return
        setLoading(true)
        try {
            const res = await axios.get(`${process.env.React_App_Server}/slots?bookedby=${user?._id}`)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        bookedSlots()
    },[])

return (
    <>
    <Helmet>
      <title>My Bookings</title>
      <meta name='description' content='See the slots you booked'></meta>
      <link rel='canonical' href='/mybooking'/>
    </Helmet>
      <div className="grid w-full px-6 lg:px-16 py-20 bg-slate-50 text-neutral-900 min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-6xl">Booked Slots</div>
            <div className="flex w-full justify-center text-center text-sm overflow-hidden mt-2">
                Timing and details of your booked slots.
            </div>
        </div>
    
        {
        loading ? 
        <div className='w-full flex justify-center items-center min-h-screen'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
      : 
      <div className=" w-full  px-6 lg:px-16 py-20 min-h-screen bg-clate-50 text-black">
      <div className='flex gap-10 font-medium'> 
        <div className="bg-green-600 px-6 rounded text-white py-2">Total Bookings</div>
        <div className='text-2xl mt-1'>{slots?.length}</div>
      </div>
        <div className="grid  justify-center gap-0.5">
        {slots?.map((card, index) =>(
                <div key={index} className='flex justify-around text-sm items-center py-1  px-3 border w-[1100px] border-green-600'>
                    <div>
                        {
                            user._id === card.bookedby ? 
                            <div className='flex items-center gap-3'>
                                <img className="h-10 w-10  rounded-full" src={user.profilePic} alt="" />
                                <div>{user.username}</div>
                            </div> :<></>
                        }
                    </div>
                    <div className=''>{card?.day}</div>
                    <div className='flex'> 
                        <div className=''>{card?.timefrom}-</div>
                        <div className=''>{card?.timeto}</div>
                    </div>
                    <div className='flex'> 
                        <div className=''>{card?.price}</div>
                       
                    </div>
                    <div className=''>{card?.status}</div>
                    <Link to={"/singleground/"+ card.groundId} className='text-sm text-white border hover:border-green-700 hover:text-green-700 font-medium px-3 py-2  rounded bg-green-600 hover:bg-white'>View Ground</Link>
                </div>
            ))}
        </div>
      </div>
      }
      </div>
      
    </>
  )
}

export default MyBooking