import React, {useEffect, useState} from 'react'
import { ToastContainer ,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import userAtom from '../../atoms/userAtom';
import locationlogo from "../../images/locationlogo.png"
import groundlogo from "../../images/groundlogo.png"
import phonelogo from "../../images/phonelogo.png"
import location from '../../images/location.png'
import left from '../../images/left-arrow.png'
import right from '../../images/right-arrow.png'
import { Helmet } from 'react-helmet-async';
import { AnimatePresence, motion } from "framer-motion";


const SingleGround = () => {

  const { id } = useParams()
  const router = useNavigate()
  const user = useRecoilValue(userAtom)
  const today = new Date();
  const[ground, setGround] = useState("")
  const[loading, setLoading] = useState(false)
  const[slotloading, setSlotLoading] = useState(false)
  const [day, setDay] = useState(today.toDateString().split(" ")[0]);
  const [slots, setSlots] = useState([]);


  const getGround = async () => {
    if(loading) return
    setLoading(true)
    try {
      const res = await axios.get(`${process.env.React_App_Server}/grounds/ground/${id}`);
      setGround(res.data)
    } catch (error) {
      toast.error("No Ground Found");
    }finally{
      setLoading(false)
      getSlots()
    } 
  };


  const getSlots = async () => {
    if(slotloading) return
    setSlotLoading(true)
    try {
      const res = await axios.get(`${process.env.React_App_Server}/slots?day=${day}&groundId=${id}`);
      setSlots(res.data)
    } catch (error) {
      toast.error("No Ground Found");
    }finally{
      setSlotLoading(false)
    } 
  };

  const handleSlots=(d)=>{
    setDay(d)
    // setTimeout(()=>{
    //   getSlots()
    // },500)
  }

  useEffect(() => {  
    getGround()
    getSlots();
  },[day])
     
const BookSlot =async(slotid)=>{
  if(user?._id){
      try{
      const res = await fetch(`${process.env.React_App_Server}/slots/bookslot/${slotid}/${user._id}`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
          }
          });
          const data = await res.json(); // updated user object
          if (data.error) {
          toast.error(data.error);
          return;
          }
          handlePaymentRedirect(data)

     }catch(err){
         toast.error("Error while Booking Slot!")
         console.log(err)
     }finally{
      // getSlots()
     }
  }else{
      router("/login")
  }
}

const handlePaymentRedirect = async(token) => {
  const paymentURL = `https://pakistan.paymob.com/api/acceptance/iframes/162990?payment_token=${token}`;
  // Open the link in a new tab
  window.open(paymentURL, '_blank');
};

const [index, setIndex] = useState(0)

const handleNext=()=>{
  if(index === ground?.photos?.length - 1){
    setIndex(0)
  }else{
    setIndex(index +1)
  }
}

const handlePrevious=()=>{
  if(index === 0){
    setIndex(ground?.photos?.length -1)
  }else{
    setIndex(index - 1)
  }
}

  return (
    <>
    <Helmet>
      <title>Ground</title>
      <meta name='description' content='See the available bookings and Book Ground'></meta>
      <link rel='canonical' href='/singleground'/>
    </Helmet>
        {
        loading ? 
        <div className='w-full flex justify-center items-center min-h-screen'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
      : 
   
    <>

      <div className="w-full text-neutral-900 bg-slate-50 px-3 sm:px-10 lg:px-20 pt-12 sm:pt-16 lg:pt-28 sm:min-h-screen">
        <div className='border-b-2 border-neutral-300 py-3'>
            <div className='text-2xl sm:text-4xl lg:text-6xl font-semibold'>{ground?.name}</div>
            <div className='mt-5 font-medium flex gap-2 sm:gap-6 lg:gap-14 text-[10px] sm:text-xs lg:text-base'>
              <div className='flex gap-1 sm:gap-2'><img className='h-4 w-4 sm:h-5 sm:w-5' src={locationlogo} alt='' /> {ground?.address}, {ground?.city}</div>
              <div className='flex gap-1 sm:gap-2'><img className='h-4 w-4 sm:h-5 sm:w-5' src={phonelogo} alt='' />  {ground?.contact}</div>
              <div className='flex gap-1 sm:gap-2'><img className='h-4 w-4 sm:h-5 sm:w-5' src={groundlogo} alt='' />  {ground?.aside} v {ground?.aside}</div>  
            </div>
        </div>        
        <div className="relative z-0 grid mx-0 lg:grid-cols-3 w-full gap-5 mt-10 sm:mt-20 h-[500px] sm:h-[900px] lg:h-[500px] overflow-hidden ">
          <div className='lg:col-span-2 w-full h-full'>
            <div className="relative w-full h-full bg-center bg-cover rounded-lg overflow-hidden">
            <AnimatePresence>
                {ground && ground?.photos && (
                  <motion.img
                    key={index} // Key prop for animation to detect changes
                    className='absolute w-full h-full object-cover object-center rounded-lg'
                    src={ground?.photos[index]}
                    alt='ground img'
                    initial={{ opacity: 0 }} // Initial state of the image
                    animate={{ opacity: 1 }} // Animation for image appearing
                    exit={{ opacity: 0 }} // Animation for image disappearing
                    transition={{ duration: 1 }} // Animation duration
                  />
                )}
              </AnimatePresence>
              <div className='absolute z-10 w-full h-full flex justify-between items-center'>
                <button onClick={handlePrevious} className="absolute left-2 z-10 flex justify-center items-center  bg-neutral-700 hover:bg-neutral-800 w-6 h-6 sm:w-12 sm:h-12 rounded-full"><img className="mr-1 h-4 w-4 sm:h-6 sm:w-6" alt="" src={left}/></button>
                <button onClick={handleNext} className="absolute z-10 right-2 flex justify-center items-center bg-neutral-700 hover:bg-neutral-800 w-6 h-6 sm:w-12 sm:h-12 rounded-full" ><img className="ml-1 h-4 w-4 sm:h-6 sm:w-6" alt="" src={right}/></button>
              </div>
            </div>  
          </div>

            <div className=' lg:col-span-1 w-full lg:h-full h-[60%]'>
              <div className='relative h-[85%] w-full border rounded-lg'>
                <img className="absolute rounded-lg h-full w-full object-cover" alt="" src={location}/>
              </div>
              
              <button className='w-full h-10 sm:h-14 font-semibold rounded text-xs sm:text-lg border-2 border-neutral-700 mt-2 sm:mt-4'>Direction</button>
            </div>       
        </div>
    </div>
    <div className="pb-20 lg:py-40  w-full bg-slate-50 px-3 sm:px-10 lg:px-20 text-black">
        <div className="grid justify-normal ">
          <div className="text-center text-2xl sm:text-4xl lg:text-6xl font-bold text-neutral-800">Booking</div>
              
          <div className="my-3 sm:my-10">
            <div className="grid grid-cols-7 text-white gap-1">    
                <button onClick={()=>handleSlots("Sat")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg border-2 border-neutral-900 font-medium ${day==="Sat"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Sat
                </button>
                <button onClick={()=>handleSlots("Sun")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Sun"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Sun
                </button>
                <button onClick={()=>handleSlots("Mon")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Mon"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Mon
                </button>
                <button onClick={()=>handleSlots("Tue")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Tue"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Tue
                </button>
                <button onClick={()=>handleSlots("Wed")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Wed"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Wed
                </button>
                <button onClick={()=>handleSlots("Thu")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Thu"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Thu
                </button>
                <button onClick={()=>handleSlots("Fri")} className={`py-1 sm:py-3 rounded text-xs sm:text-lg font-medium border-2 border-neutral-900 ${day==="Fri"? "bg-neutral-900": "text-neutral-900 hover:bg-neutral-300 duration-300"}`}> 
                    Fri
                </button>
            </div>
            <div className='grid gap-3 mt-3 sm:mt-10'>
            <div className='flex justify-between gap-3 sm:px-8 text-black font-medium'>
                <div className='flex justify-between gap-3 sm:gap-10 lg:gap-44 text-xs sm:text-base'>
                    <div className=''>Slot</div>
                    <div className=''>Time</div>      
                    <div className=''>Price</div>
                </div>
                <div className=' sm:pr-8 md:pr-20 text-xs sm:text-bas'>Status</div>
            </div>
            {slotloading ? 
            <div className='w-full min-h-[300px] flex justify-center'>
              <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
              </div>
            </div>   :
            <div className='min-h-[300px]'>{
                    slots?.map((slot, index)=>(
                            <div key={index} className='mt-1  flex justify-between px-10 border-2 border-neutral-500 text-sm sm:text-base hover:border-neutral-700 rounded'>
                                <div className='flex justify-between sm:gap-10 lg:gap-40 my-3'>
                                    <div className=''>{index+ 1}</div>
                                    <div className=' '>{slot.timefrom}-{slot.timeto}</div> 
                                    <div className=''>Rs.{slot.price}</div>             
                                </div>
                                
                                <div className='flex items-center gap-16 lg:pr-12'> 
                                    { 
                                            slot.status === "Available" ? <button onClick={()=>BookSlot(slot._id)} name='available' value={slot.status} className='bg-neutral-900 hover:bg-white text-white hover:text-neutral-900 border-2 border-neutral-900 duration-300 font-medium px-6 rounded  sm:text-sm my-1 py-2'>Book</button> : <div aria-disabled className=' text-sm w-20 rounded flex items-center justify-center text-white bg-neutral-400 my-1 py-2'>{slot.status}</div>  
                                    } 
                                    <div>
                                    {
                                        ((slot.bookedby !== undefined)&&(slot.bookedby !== null)) ?  
                                        <Link to={'/playerdetail/'+ slot.bookedby} className='flex items-center text-xs text-gray-600 hover:text-black underline'>booked by</Link> : <></>
                                    }
                                      
                                    </div>
                                            
                                </div>
                            </div>    
                        ))
                  }
                  </div>
              }
              </div>
          </div>
          </div>
      </div>
    <ToastContainer />
 
    </>
}
</>  
  )
}

export default SingleGround