import React, {useEffect, useState} from 'react'
import { ToastContainer ,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import userAtom from '../../atoms/userAtom';
import { useRecoilState } from 'recoil';
import fixtureAtom from '../../atoms/fixtureAtom';
import defclub from '../../images/defclub.png'
import locationlogo from "../../images/locationlogo.png"
import groundlogo from "../../images/groundlogo.png"
import { Helmet } from 'react-helmet-async';


const SingleFixture = () => {

  const [user, setUser] = useRecoilState(userAtom);
  const {id} = useParams()
  const[fixture, setFixture] = useRecoilState(fixtureAtom)
  const [challenges, setChallenges] = useState([])
  const[loading, setLoading] = useState(false)
  const[loadingc, setLoadingC] = useState(false)
  const[loadingr, setLoadingR] = useState(false)
  const[pending, setPending] = useState(false)


  const [resultdata, setResultData] = useState({
    teamAscore: "",
    teamBscore: "", 
    });

    let name, value;
    const postData=(e)=>{
        name = e.target.name;
        value = e.target.value;

        setResultData({...resultdata, [name]:value})
    }

  const getFixture = async () => {
    if(loading) return
    setLoading(true)
    try {    
      const fixtureres = await axios.get(`${process.env.React_App_Server}/fixtures/single/${id}`)
      setFixture(fixtureres.data)
      if((user?.teamId === fixtureres?.data?.teamA) && (fixtureres?.data?.resultApending === true)){
        setPending(fixtureres?.data?.resultApending)
      }
      if((user?.teamId === fixtureres?.data?.teamB) && (fixtureres?.data?.resultBpending=== true)){
        setPending(fixtureres?.data?.resultBpending)
      }
      const challengeres = await axios.get(`${process.env.React_App_Server}/teams/teams?tochallenge=${id}`)
      setChallenges(challengeres.data.teams)      
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }


 useEffect(()=>{
  getFixture()
},[])

 
const sendChallenge =async()=>{
  if(loadingc) return;
  setLoadingC(true);
  try {
    if(!user?.teamId){
        toast.warning("Create Your Own Team to Send Challenges!")
        return
    }
    const res = await fetch(`${process.env.React_App_Server}/fixtures/sendchallenge/${fixture._id}/${user.teamId}`, {
      method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwtToken")
          },
    });
    const data = await res.json(); // updated user object
    if (data.error) {
      toast.error(data.error)
      return;
    }
    toast.success(data)
  } catch (error) {
    console.log(error)
  } finally {
    setLoadingC(false);
  }
};

const AcceptChallenge = async(teamId)=>{
    try {
      const res = await fetch(`${process.env.React_App_Server}/fixtures/acceptchallenge/${fixture._id}/${teamId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        }
      });
      const data = await res.json(); // updated user object
      if (data.error) {
        toast.error(data.error);
        console.log(data.error)
        return;
      }
      console.log(data)
      toast.success(data);
    } catch (error) {
      console.log(error)
    }finally{
      getFixture()
    }
  }

  const RejectChallenege = async(teamId)=>{
    try {
      const res = await fetch(`${process.env.React_App_Server}/fixtures/rejectchallenge/${fixture._id}/${teamId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        }
      });
      const data = await res.json(); // updated user object
      if (data.error) {
        toast.error(data.error);
        console.log(data.error)
        return;
      }
      console.log(data)
      toast.warn(data);
    } catch (error) {
      console.log(error)
    }finally{
        getFixture()
    }
  }

  const handleResult= async(e)=>{
    e.preventDefault();
    if(loadingr) return
    setLoadingR(true)
    try {
      const res = await fetch(`${process.env.React_App_Server}/fixtures/fixtureresult/${fixture._id}/${user?.teamId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        },
        body: JSON.stringify({ ...resultdata }),
      });
      const data = await res.json(); // updated user object
      if (data.error) {
        toast.error(data.error);
        console.log(data.error)
        setLoadingR(false)
        return;
      }
      console.log(data)
      toast.success(data);
    } catch (error) {
      console.log(error)
    }finally{
      setPending(false)
      setLoadingR(false)
      getFixture()
    }
  }
     

return (
  <>
    <Helmet>
      <title>Fixture</title>
      <meta name='description' content='Challange the Team to Play Match against them'></meta>
      <link rel='canonical' href='/singlefixtures'/>
    </Helmet>
   {
        (user?.teamId === fixture?.teamA || user?.teamId === fixture?.teamB) && pending && fixture?.status ? 
        <>
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-lg max-h-full">
                <div className="relative bg-neutral-800 rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setPending(false)}} className="absolute top-3 right-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 ">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg text-gray-400 dark:text-gray-400">Please Enter Result</h3>
                         <form onSubmit={handleResult}> 
                          <div className='flex justify-center gap-3 text-white mb-10 '>
                              <div className='grid justify-end'>
                                <div className='flex justify-center text-center font-medium w-36 whitespace-nowrap truncate'>{fixture?.teamAname}</div>
                                <input min={0} max={30} required type='number' name='teamAscore' value={resultdata?.teamAscore} onChange={postData} placeholder='Total Goals' className='text-gray-700 text-xs py-3 sm:text-sm  text-center w-36 rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                              </div>
                              <div className='flex items-center mt-5'>--</div>
                              <div className='grid justify-start'>
                                <div className='flex justify-center text-center font-medium w-36 whitespace-nowrap truncate'>{fixture?.teamBname}</div>
                                <input min={0} max={30} required type='number' name='teamBscore' value={resultdata?.teamBscore} onChange={postData} placeholder='Total Goals' className='text-gray-700 text-xs py-3 sm:text-sm text-center  w-36 rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                              </div>
                          </div>
                          <div className='flex gap-6 justify-center'>
                            <button onClick={() =>{setPending(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-7 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Wait</button>
                            <button disabled={loadingr} type='submit' className="text-white bg-green-600 hover:bg-green-800 border border-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                {loadingr ? "Submitting...": "Submit"}
                            </button>
                          </div>  
                        </form>           
                    </div>
                </div>
            </div>
        </div>
        </>:
        <></>
        }
     {loading ? 
        <div className='w-full flex justify-center items-center min-h-screen'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
      <>
          <div className="w-full text-neutral-800 bg-slate-100 px-20 min-h-screen pb-40">
            <div className='grid grid-cols-5 bg-white w-full p-20 pt-40 rounded-b-3xl shadow-2xl border-2'>
              <div className='col-span-2 grid justify-center'>
                <div className='relative h-40 w-40 rounded overflow-hidden'>
                  <img className='absolute h-full w-full object-cover rounded' src={fixture?.teamAlogo} alt=''/>
                </div>
                <div className='mt-3 text-xl font-medium text-center'>{fixture?.teamAname}</div>
              </div>
              <div className='col-span-1 grid justify-center items-center'>
                <div>
                  <div className=' text-center'>{fixture?.date}</div>
                  <div className='text-2xl font-medium text-center'>{fixture?.time}</div>
                  <div className='text-gray-600 text-center'>{fixture?.aside}v{fixture?.aside}</div>
                </div>
                {
                  fixture?.verified && (
                    <div className='grid justify-center font-medium'>
                      <div>Full Time</div>
                      <div className='flex justify-center text-4xl'>
                        <div>{fixture?.finalscore?.teamAscore}</div>
                        <div className='px-3'>-</div>
                        <div>{fixture?.finalscore?.teamBscore}</div>
                      </div>
                    </div>
                  )

                }     
              </div>
              <div className='col-span-2 grid justify-center'>
                <div className='relative h-40 w-40 rounded overflow-hidden'>
                  <img className='absolute h-full w-full object-cover rounded' src={fixture?.teamBlogo || defclub} alt=''/>
                </div>
                <div className='mt-3 text-xl font-medium text-center'>{fixture?.teamBname || "TBD"}</div>
              </div>
            </div>
            { user?.teamId ?
            <div className='grid grid-cols-2'>
              <div>
                <div className='text-lg font-medium text-red-500'>Note:</div>
                <div className='text-sm font-medium text-gray-500'>{ "once you accepted the challenge fixture will not update."}</div>
                <div className='text-sm font-medium text-gray-500'>{ "If any of the two teams entered wrong result it will not be verified."}</div>
              </div>
              <div className='flex justify-end gap-10 mt-5'>
                {
                  (user?.teamId === fixture?.teamA  && !fixture?.status) ?
                    <Link to={"/fixturegig/"+id} className='px-8 py-3 bg-white border-2 border-neutral-700 hover:bg-gray-100 rounded-lg  font-medium'>Update</Link>
                  : 
                  <>
                  {
                    ((fixture?.teamB === null) && (fixture?.teamA !== user?.teamId) && (fixture?.status !== true)) && (
                      <button onClick={sendChallenge}  className='px-8 py-3 bg-neutral-700 border-2 border-neutral-700 hover:bg-neutral-900 rounded-lg text-white font-medium'>{loadingc ? "Challenging..." : "Send Challenge"}</button>
                    )
                  }
                  </>
                }
              </div>   
            </div>
           :<></>
          }
            <div className='w-full flex justify-center mt-10'>
              <div className='grid justify-center bg-white w-[60%] rounded-xl py-3 border shadow' >
                <div className='flex gap-10'>
                  <div className='flex gap-1'>
                    <img className='h-8 w-8' src={groundlogo} alt=''/>
                    <div className='text-xl font-medium'>{fixture?.groundname},</div>
                  </div>
                  <div className='flex gap-1'>
                    <img className='h-8 w-8' src={locationlogo} alt=''/>
                    <div className='text-xl font-medium'>{fixture?.groundaddress}</div>
                  </div>
                </div>
                <div className='font-medium mt-6 text-center'>{fixture?.messege}</div>
              </div>
            </div>
            { (user?.teamId === fixture?.teamA || user?.teamId === fixture?.teamB) ?
              <div className='flex gap-3 justify-center text-sm text-gray-500'>
                <div className='font-medium'>Status:</div>
                <div>{fixture?.resultmessege || "In Progress"}</div>
              </div>
            :<></>
            }
            { (user?.teamId === fixture?.teamA && fixture?.status !== true) && (
              <div className='text-2xl font-medium mt-10'>Challenges</div>
            )}
          
            {loading ? 
              <div className='w-full flex justify-center items-center min-h-screen'>
                  <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status">
                      <span
                      className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span>
                  </div>
              </div> 
              : 
            <div className="mt-10 grid grid-cols-6">
              { (user?.teamId === fixture?.teamA) && !fixture?.status && challenges && challenges?.map((card, index) =>(
                  <div key={index} className='grid justify-center rounded-lg bg-white p-3 overflow-hidden border shadow'>
                    <div className='relative h-32 w-32 rounded overflow-hidden'>
                      <img className='absolute h-full w-full object-cover' src={card.logo} alt=''/>
                    </div>
                    <div className='text-center text-lg font-medium'>{card?.name}</div>
                    <div className='flex justify-between gap-3'>
                      <button onClick={()=>RejectChallenege(card._id)} className='px-2 border border-neutral-900 text-neutral-900 hover:bg-gray-200 text-sm font-medium rounded'>Reject</button>
                      <button onClick={()=>AcceptChallenge(card._id)} className='px-2 border border-green-600 text-white bg-green-600 hover:bg-green-700 text-sm font-medium rounded'>Accept</button>
                    </div>
                  </div>
                ))}
            </div>
            }
          </div>    
      </>       
      }
 
 
 <ToastContainer />
  </>
)
}

export default SingleFixture