import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { FixtureCard } from '../../components';
import { Helmet } from 'react-helmet-async';



const Fixtures = () => {

    const [fixtures, setFixtures] = useState([])
    const[loading, setLoading] = useState(false)

  
    const getFixtures = async () => {
      if(loading) return
      setLoading(true)
      try {
        const res = await axios.get(`${process.env.React_App_Server}/fixtures/all`)
        setFixtures(res.data)
      
      } catch (error) {
        console.log(error)
      }finally{
        setLoading(false)
      }
    }


    useEffect(()=>{
      getFixtures()
    },[])

  return (
    <>
    <Helmet>
      <title>Fixtures</title>
      <meta name='description' content='Challange the teams to play matches or create your own fixture'></meta>
      <link rel='canonical' href='/fixtures'/>
    </Helmet>
     <div className="grid w-full py-14 sm:py-20 lg:py-32 px-3 sm:px-10 lg:px-20  bg-slate-50 text-neutral-900 min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-xl sm:text-4xl lg:text-6xl">Any Team Looking for a Match</div>
            <div className="flex w-full justify-center text-center text-xs sm:text-sm overflow-hidden mt-2 px-3">
                Challange the best team and push yourself to limit.
            </div>
        </div>
       {loading ? 
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
      <div className=" w-full px-3 sm:px-6 lg:px-20 py-6 sm:py-10 lg:py-20 min-h-screen bg-clate-50 text-black">
        <div className='flex justify-between gap-10 font-medium w-full'> 
        
        </div>
        <div className="mt-10 grid gap-5 sm:gap-10 justify-center w-full ">
        { fixtures && fixtures?.map((card, index) =>(
                <FixtureCard key={index} card={card} />
            ))}
        </div>
      </div>
      }
      </div>
    </>
  )
}

export default Fixtures