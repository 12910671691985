import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
 

const TeamCard = () => {
    const [team, setTeams] = useState([]);
    const[loading, setLoading] = useState(false)

    useEffect(()=>{
        const getTeam = async() =>{
            setLoading(true)
            try{
                const res = await axios.get(`${process.env.React_App_Server}/teams/teams`);
                setTeams(res.data.teams)
            }catch(e){
                console.log(e);
            }finally{
                setLoading(false)
            }
        }

        getTeam();
    },[])

  return (
    <>
    {loading ? 
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
        <div className='flex flex-wrap justify-center gap-10 md:gap-20'>
            {team?.map((card, index) =>(
                <Link to={'/singleteam/' + card?._id} key={index} className='w-24 sm:w-40 hover:scale-110 duration-300 hover:ease-in-out text-neutral-900  cursor-pointer'>
                    <div className='relative h-24 w-24 sm:h-40 sm:w-40 rounded overflow-hidden'>
                        <img className="absolute object-cover h-full w-full " src={card?.logo} alt=""/>
                    </div>
                    <div className="text-base sm:text-2xl mt-3 w-full whitespace-nowrap truncate text-center text-black font-semibold">
                        {card?.name}
                    </div>
                </Link>        
            ))}
        </div>
    }
    </>
  )
}

export default TeamCard