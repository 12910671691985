import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import groundAtom from '../../atoms/groundAtom';
import { Link, useNavigate } from 'react-router-dom';
import footballground from '../../images/footballground.webp'
import userAtom from '../../atoms/userAtom';
import { Helmet } from 'react-helmet-async';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { imagedb } from '../../firebase-config';
import deletelogo from '../../images/deletelogo.png'
import swaplogo from '../../images/swaplogo.png'


const GroundGig = () => {

    const user = useRecoilValue(userAtom);
    const[ground, setGround] = useRecoilState(groundAtom);
    const [loading, setLoading] = useState(false);
    const [imgs, setImgs] = useState(ground?.photos)
    const [imgloading, setImgLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const[delImg, setDelImg]=useState(false)
    const route = useNavigate()

    const handleDelete =async()=>{
        try {
          const res = await fetch(`${process.env.React_App_Server}/grounds/delete/${user?.groundId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
            }
          });
          console.log(res)
          setGround(null);
          toast.warn("Ground Deleted Successfully!");
          setTimeout(() =>{
            route("/ground") 
          },1000)
        } catch (error) {
            console.log(error);
          toast.error("Error");
        } 
    }

    const [userdata, setUserData] = useState({
        photos: ground?.photos || "", 
        ownername: ground?.ownername || "",
        ownercontact:ground?.ownercontact || "",
        email:ground?.email || "",
        city: ground?.city || "",
        name: ground?.name || "",
        aside: ground?.aside || "",
        address: ground?.address || "",
        contact: ground?.contact || "", 
        weekendmorning: ground?.weekendmorning || "",
        weekendevening: ground?.weekendevening || "",
        weekdaymorning: ground?.weekdaymorning || "",
        weekdayevening: ground?.weekdayevening || "",          
    })

    let name, value;
    const postData=(e)=>{
        name = e.target.name;
        value = e.target.value;

        setUserData({...userdata, [name]:value})
    }

    const uploadImage = async (e) => {
        const selectedFiles = e.target.files;
    
        if (imgloading) return;
        setImgLoading(true);
    
        let total = userdata.photos.length
        total = total + selectedFiles.length
        if (total > 3) {
            toast.warn('You can add Maximum 3 images.');
            e.target.value = null;
            setImgLoading(false);
            return;
        }
    
        try {
            // Initialize tempImgs with existing images if available, otherwise start with an empty array
            let tempImgs = userdata.photos ? [...userdata.photos] : [];
            for (let index = 0; index < selectedFiles.length; index++) {
                const imageFile = selectedFiles[index];
                if (imageFile.size > 5200000) {
                    toast.warning("Upload Image less than 4MB");
                    return;
                }
                const imgRef = ref(imagedb, `ground/${user._id}/groundimg${index + tempImgs.length}`);
                await uploadBytes(imgRef, imageFile);
                const url = await getDownloadURL(imgRef);
                tempImgs.push(url);
                toast.success(`Image ${index + 1} uploaded successfully.`);
            }
            setImgs(tempImgs);
            userdata.photos = tempImgs;
            if (ground) {
                handleGround();
            }
        } catch (error) {
            console.log(error);
            toast.error("Error uploading logo");
        } finally {
            setImgLoading(false);
            setDelImg(false);
        }
    };
    
    

const handleGround = async (e) => {

    if (loading) return;
    setLoading(true);
 
        if(ground){
            if(!user?.groundId){
                setDelImg(false)
                return
            }
            try {                
                const res = await fetch(`${process.env.React_App_Server}/grounds/update/${user?.groundId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("jwtToken")
                },
                body: JSON.stringify({ ...userdata }),
                });
                const data = await res.json(); // updated user object
                if (data.error) {
                toast.error(data.error);
                return;
                }
                setGround(data);
                toast.success("Ground Updated Successfully!");
                } catch (error) {
                    console.log(error);
                  toast.error("Error");
                }finally {
                    setLoading(false);
                    setDelImg(false)
                }
        }else{
            
            try{
                userdata.photos = imgs
                const res = await fetch(`${process.env.React_App_Server}/grounds/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("jwtToken")
                },
                body: JSON.stringify({ ...userdata, owner: user?._id }),
                });
                console.log(res)
                const data = await res.json(); // updated user object
                if (data.error) {
                toast.error(data.error);
                return;
                }
                setGround(data);
                toast.success("Ground Created Successfully!");
                if(!delImg){
                    setTimeout(() =>{
                        route("/myground") 
                      },1000)
                }
                } catch (error) {
                    console.log(error);
                  toast.error("Error");
                } finally {
                    setLoading(false);
                    setDelImg(false)
                }
        }     
  };

  const deleteImage = async (i) => {
    if(delImg) return

    setDelImg(true)
    try {
        if (i >= 0 && i < imgs.length) {
            const updatedImgs = [...imgs]; // Create a shallow copy of the imgs array
            updatedImgs.splice(i, 1); // Remove the element at index i
            setImgs(updatedImgs)
            userdata.photos = updatedImgs
            handleGround()
        }
    } catch (error) {
        console.log(error);
    }
}

const swapImage = (i)=>{
        const updatedImgs = [...imgs]; // Create a shallow copy of the imgs array
        let temp = updatedImgs[i]
        updatedImgs[i] = updatedImgs[i+1]
        updatedImgs[i+1] = temp
        setImgs(updatedImgs)
        userdata.photos = updatedImgs
        handleGround()
}

useEffect(()=>{
    if(!ground){
        route("/myground") 
    }
},[])


  return (
    <>
    <Helmet>
      <title>Create Ground</title>
      <meta name='description' content='Become Partner with OrKhylo and add your Ground.'></meta>
      <link rel='canonical' href='/groundgig'/>
    </Helmet>
       {
        deleting ? 
        <>
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 pt-20 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setDeleting(false)}} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete your Ground?</h3>
                        <button onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button onClick={() =>{setDeleting(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>:
        <></>
       }
        <div className="min-h-screen flex justify-center items-center bg-slate-50 text-black pt-[72px]">
            <div className="lg:w-[80%] ">
            <div className="flex justify-center items-center bg-bottom bg-cover rounded-xl overflow-hidden" style={{ backgroundImage: `url('${footballground}')`}}>
                <div className='flex justify-center items-center text-center text-white font-bold text-6xl w-full h-72 bg-black/90 animate-pulse  backdrop-blur-[3px] rounded-xl'>Manage Ground Info</div>
            </div>
            {
                user.groundId &&(
                    <div className='mt-5 flex justify-center'>
                        <Link to={"/myground"} className=' px-12 py-3 bg-neutral-900 hover:bg-white border-2 border-neutral-900 text-white hover:text-neutral-900 font-medium duration-100 rounded'>Go Back</Link>  
                    </div>
                )
            }
            <div className="pl-10 sm:pl-0  mt-20 mb-10 text-lg sm:text-2xl text-black font-semibold">Ground Images (3)</div>
              
              <div className="flex ">
                  <div className='grid grid-cols-3 '>
                      {
                        imgs && imgs?.map((img, index)=>(
                            <div className='flex items-center '>
                                <div key={index} className='flex justify-end h-40 w-56 rounded transition ease-in-out delay-100 hover:brightness-50  duration-700 overflow-hidden'>
                                    <button onClick={()=>{deleteImage(index)}} className='absolute hover:bg-gray-100 bg-gray-400 p-1 mt-1 mr-1 rounded'>
                                        <img className='h-6 w-6' src={deletelogo} alt='delete'/>
                                    </button>  
                                    <img className='h-full w-full rounded flex justify-center items-center' src={img} alt='upload ground images'/>
                                </div>
                                {
                                    index < imgs.length -1 && (
                                        <button onClick={()=>swapImage(index)} className='mx-3'>
                                            <img className='h-8 w-8' src={swaplogo} alt='swap'/>
                                        </button>
                                    )
                                }             
                            </div>
                        ))
                      }                
                  </div>   
                  {
                      imgloading || delImg ? 
                          <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                          </span>
                      </div> :
                      <div className='h-14 mt-20 grid justify-center items-center ml-8'>
                          <input multiple size={3}  className='text-xs' accept='image/jpeg, image/png, image/jpg, image/jfif, image/webp' type='file' onChange={(e)=>uploadImage(e)}/>
                          <div className='text-xs text-gray-600 font-normal'>max 5MB</div>
                      </div>
                  }
              </div>
            <form onSubmit={handleGround} className="px-20" required={true}>
                <div className="pl-10 sm:pl-0  mt-20 mb-10 text-lg sm:text-2xl text-black font-semibold">Ground Info</div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Owner Name</div>
                        <input required type="text" name='ownername' value={userdata?.ownername} onChange={postData} placeholder='Enter Ground Owner Name' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Owner Email</div>
                        <input required type='email' name='email' value={userdata?.email} onChange={postData} placeholder='Enter Owner Email' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Owner Contact</div>
                        <input maxLength={11} required type='tel' name='ownercontact' value={userdata?.ownercontact} onChange={postData} placeholder='Enter Ground Owner Contact' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground Contact</div>
                        <input required maxLength={11} type='tel' name='contact' value={userdata?.contact} onChange={postData} placeholder='Enter Contact Available in Ground' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                <div className="grid justify-center sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground Name</div>
                        <input required type="text" name='name' value={userdata?.name} onChange={postData} placeholder='Enter Ground Name' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full mt-3 sm:mt-0">
                        <div className='sm: text-neutral-700 font-medium text-sm sm:text-base'>Ground A-side <span className="text-xs"> ( e.g; 11-a-side )</span></div>
                        <select required={true} type="text" name='aside' value={userdata?.aside} onChange={postData} className=' text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'>
                            <option className="bg-neutral-700 text-white">0</option>
                            <option className="bg-neutral-700 text-white">5</option>
                            <option className="bg-neutral-700 text-white">6</option>
                            <option className="bg-neutral-700 text-white">7</option>
                            <option className="bg-neutral-700 text-white">8</option>
                            <option className="bg-neutral-700 text-white">11</option>
                            <option className="bg-neutral-700 text-white">All</option>
                        </select>
                    </div>
                </div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground City</div>
                        <input required type="text" name='city' value={userdata?.city} onChange={postData} placeholder='Enter Ground City' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground Address</div>
                        <input required type='text' name='address' value={userdata?.address} onChange={postData} placeholder='Enter Ground Address' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                
                
                <div className="pl-10 sm:pl-0  mt-20 mb-10 text-lg sm:text-2xl text-black font-semibold">Booking Price Avg</div>
                <div className="grid justify-center  sm:grid-cols-2  w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Weekend</div>
                        <input max={10000} required type='number' name='weekendevening' value={userdata?.weekendevening} onChange={postData} placeholder='Enter Weekend Price' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Weekdays</div>
                        <input max={10000} required type='number' name='weekdayevening' value={userdata?.weekdayevening} onChange={postData} placeholder='Enter Weekdays Price' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                <div className="grid sm:flex justify-center mb-40 mt-20">
                
                    <button disabled={loading} type='submit' className=" rounded border-2 border-neutral-800 hover:bg-white py-3 px-10 text-white hover:text-neutral-800 font-semibold bg-neutral-800 shadow">{loading? "Loading..." : ground?._id ? "Update":"Create"}</button>
                </div>
                 
                </form>
                {
                    ground?._id ?
                    <>
                    <button onClick={() =>{setDeleting(!deleting)}} className="my-20 rounded-lg border border-red-600 hover:border-red-400 py-3 px-4 text-red-600 hover:text-red-400 font-semibold">
                        Delete Ground
                    </button>
                    </>:<></>
                }   
                </div>
      </div>  
      <ToastContainer />
    </>
  )
}

export default GroundGig