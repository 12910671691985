import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SlotModal = ({ onSubmit, day, groundId, slot}) => {

    const [slotdata, setSlotData] = useState({
        price:slot?.price,
        timefrom:slot?.timefrom,
        timeto:slot?.timeto,
        status: slot?.status
      });
    
      let name, value;
      const postData =(e)=>{
        name = e.target.name;
        value = e.target.value;
    
        setSlotData({...slotdata, [name]:value})
      }

      const [updating, setUpdating] = useState(false);

      const handleSubmit = async (e) => {
        e.preventDefault();
        if (updating) return;
        setUpdating(true);
        if(slot){
          try {
            const res = await fetch(`${process.env.React_App_Server}/slots/update/${slot?._id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("jwtToken")
              },
              body: JSON.stringify({ ...slotdata }),
            });
            
            const data = await res.json(); // updated user object
            if (data.error) {
              toast.error(data.error);
              return;
            }
  
            toast.success("Slot Updated Successfully!");
         
          } catch (error) {
            toast.error("Error while updating Slot!");
          } finally {
            setUpdating(false);
          }
        }else{
          try {
            const res = await fetch(`${process.env.React_App_Server}/slots/${day}/${groundId}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("jwtToken")
              },
              body: JSON.stringify({ ...slotdata }),
            });
            const data = await res.json(); // updated user object
            if (data.error) {
              toast.error(data.error);
              return;
            }
            toast.success("Slot Added Successfully!");
            setTimeout(() =>{
              onSubmit()
            },1000)
          } catch (error) {
            toast.error("Error while Adding Slot!");
          } finally {
            setUpdating(false);
          }
        }
      
      };

  return (
    <>
        <div className="grid justify-center items-center fixed top-0 left-0 right-0 z-10 inset-0 backdrop-blur">
        {/* <button className="h-10 w-10 bg-red-600 rounded text-white  flex justify-center items-center" onClick={onSubmit}>X</button> */}
        <form onSubmit={handleSubmit}>
        
        <div className=" grid gap-4 w-[500px] text-black font-medium border-2 px-6 py-10 bg-green-50 rounded-2xl shadow-xl">
          <div className="text-xl fint-semibold mb-3">Adding Slot to <span className="font-bold text-3xl">{day}</span></div>
    
          <div className="grid grid-cols-2 gap-4">
            <div className="text-left grid">Status
                <select required={true} value={slotdata?.status}  onChange={postData} type="" name='status' className="py-2  text-black text-xs  pl-6  bg-transparent w-full rounded-xl border-4 shadow-md shadow-green-400 border-green-500 focus:outline-none focus:border-green-300">
                    <option>Unavailable</option>
                    <option>Available</option>
                    <option>Reserved</option>
                </select>
            </div>
            <div className="text-left grid">Price
                <input required={true} autoComplete='off' value={slotdata?.price}  onChange={postData} type="number" name='price' className="py-2  text-black text-xs  pl-6 bg-transparent w-full rounded-xl border-4 shadow-md shadow-green-400 border-green-500 focus:outline-none focus:border-green-300" placeholder="Enter Price Rs"/>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-left grid">Time From
                <input required={true} autoComplete='off' value={slotdata?.timefrom}  onChange={postData} type="time" name='timefrom' className="py-2  text-black text-xs  pl-6  bg-transparent w-full rounded-xl border-4 shadow-md shadow-green-400 border-green-500 focus:outline-none focus:border-green-300" placeholder="Enter Username"/>
            </div>
            <div className="text-left grid">Time To
                <input required={true} autoComplete='off' value={slotdata?.timeto}  onChange={postData} type='time' name='timeto' className="py-2  text-black text-xs  pl-6 bg-transparent w-full rounded-xl border-4 shadow-md shadow-green-400 border-green-500 focus:outline-none focus:border-green-300" placeholder="Enter Email"/>
            </div>
          </div>
          <div className='flex justify-end mt-8'>  
            <button disabled={updating}  type='submit' className='py-2  px-6 text-white text-xs bg-green-600 hover:bg-green-700 rounded'>{updating ? <span className='text-xs'>Loading...</span>: <span>{slot ? <span>Update</span> : <span>Add</span>}</span> }</button>
          </div>
        </div>
        </form>
        </div>
        <ToastContainer />
    </>
  )
}

export default SlotModal