import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link, useNavigate, useParams } from 'react-router-dom';
import teamimg from '../../images/createteam.webp'
import fixtureAtom from '../../atoms/fixtureAtom';
import userAtom from '../../atoms/userAtom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const CreateFixture = () => {

    const {id} = useParams()
    const user = useRecoilValue(userAtom);
    const [fixture, setFixture] = useRecoilState(fixtureAtom);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const route = useNavigate()

    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate());
    
    const currentDate = tomorrowDate.toISOString().split('T')[0];
    
    // Calculate maximum date (1 month ahead) in Islamabad timezone
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);
    const maxDateString = maxDate.toISOString().split('T')[0];

    const [fixturedata, setFixtureData] = useState({
    teamA: "",
    teamAlogo: "",
    teamAname: "",
    groundname: fixture?.groundname || "" ,
    groundaddress: fixture?.groundaddress || "",
    date: fixture?.date || "",
    time: fixture?.time || "",
    aside: fixture?.aside || "Select A-Side",
    messege: fixture?.messege || "",
    });

    let name, value;
    const postData=(e)=>{
        name = e.target.name;
        value = e.target.value;

        setFixtureData({...fixturedata, [name]:value})
    }

    useEffect(()=>{
        if(id === '0'){
            console.log("Hi")
            setFixture(null)
        }
        if( id !== '0' && !fixture){
            route("/teamfixtures/"+user?.teamId)    
        }
    },[])

    const handleDelete =async()=>{
        try {
          const res = await fetch(`${process.env.React_App_Server}/fixtures/delete/${fixture._id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
            }
          });
          const data = await res.json(); // updated user object
          if (data.error) {
          toast.error(data.error);
          return;
          }
          setFixture(null);
          toast.warning(data);
          setTimeout(()=>{
            route("/teamfixtures/"+user?.teamId)
          },500)
        } catch (error) {
            console.log(error);
          toast.error("Error");
        }
    }

    const createFixture = async (e) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
       
            if(id !=='0'){
                try {
                    const teamres = await axios.get(`${process.env.React_App_Server}/teams/team/${user?.teamId}`)
                    fixturedata.teamA = teamres?.data?._id
                    fixturedata.teamAlogo = teamres?.data?.logo
                    fixturedata.teamAname = teamres?.data?.name  
                    const res = await fetch(`${process.env.React_App_Server}/fixtures/update/${fixture._id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("jwtToken")
                    },
                    body: JSON.stringify({ ...fixturedata }),
                    });
                    const data = await res.json(); // updated user object
                    if (data.error) {
                    toast.error(data.error);
                    return;
                    }
                    setFixture(data);
                    toast.success("Fixture Updated Successfully!");
                    setFixture(null)
                    setTimeout(() =>{
                        route("/teamfixtures/"+user?.teamId) 
                      },2000)
                    } catch (error) {
                        console.log(error);
                      toast.error("Error");
                    }finally {
                        setLoading(false);
                    }
            }else{
                try{
                    const teamres = await axios.get(`${process.env.React_App_Server}/teams/team/${user?.teamId}`)
                    fixturedata.teamA = teamres?.data?._id
                    fixturedata.teamAlogo = teamres?.data?.logo
                    fixturedata.teamAname = teamres?.data?.name  

                    const res = await fetch(`${process.env.React_App_Server}/fixtures/create/${user?.teamId}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("jwtToken")
                    },
                    body: JSON.stringify({ ...fixturedata}),
                    });
                    console.log(res)
                    const data = await res.json(); // updated user object
                    if (data.error) {
                    toast.error(data.error);
                    return;
                    }
                    toast.success("Fixture Created Successfully!");
                    setFixture(null)
                    setTimeout(() =>{
                        route("/teamfixtures/"+user?.teamId) 
                      },1000)
                    } catch (error) {
                        console.log(error);
                      toast.error("Error");
                    } finally {
                        setLoading(false);
                    }
            }     
      
      };


  return (
    <>
    <Helmet>
      <title>Create Fixtures</title>
      <meta name='description' content='Create your own fixture'></meta>
      <link rel='canonical' href='/fixturegig'/>
    </Helmet>
        {
        deleting ? 
        <>
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setDeleting(false)}} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete your Team?</h3>
                        <button onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button onClick={() =>{setDeleting(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>:
        <></>
        }
        <div className="min-h-screen flex justify-center items-center bg-slate-50 text-black pt-[72px]">
            <div className="lg:w-[80%] ">
            <div className="flex justify-center items-center bg-bottom bg-cover rounded-xl overflow-hidden" style={{ backgroundImage: `url('${teamimg}')`}}>
                <div className='flex justify-center items-center text-center text-white font-bold text-6xl w-full h-72 bg-black/90 animate-pulse  backdrop-blur-[3px] rounded-xl'>Create Fixture</div>
            </div>
            <div className='mt-5 flex justify-center'>
                <Link to={"/teamfixtures/"+user?.teamId} className=' px-12 py-3 bg-neutral-900 hover:bg-white border-2 border-neutral-900 text-white hover:text-neutral-900 font-medium duration-100 rounded'>Go Back</Link>  
            </div>
            <form onSubmit={createFixture} className="px-20" required={true}>            
            <div className="pl-10 sm:pl-0  mt-20 mb-10 text-lg sm:text-2xl text-black font-semibold">Fixture Info</div>
            <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground Name</div>
                        <input required type="text" name='groundname' value={fixturedata?.groundname} onChange={postData} placeholder='Enter Ground Name' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full mt-3 sm:mt-0">
                        <div className='sm: text-neutral-700 font-medium text-sm sm:text-base'>Ground A-side <span className="text-xs"> ( e.g; 11-a-side )</span></div>
                        <select required={true} type="text" name='aside' value={fixturedata?.aside} onChange={postData} className=' text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'>
                            <option className="bg-neutral-700 text-white">0</option>
                            <option className="bg-neutral-700 text-white">5</option>
                            <option className="bg-neutral-700 text-white">6</option>
                            <option className="bg-neutral-700 text-white">7</option>
                            <option className="bg-neutral-700 text-white">8</option>
                            <option className="bg-neutral-700 text-white">11</option>
                            <option className="bg-neutral-700 text-white">All</option>
                        </select>
                    </div>
                </div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Date</div>
                        <input required={true} type='date' name='date' value={fixturedata?.date} onChange={postData} min={currentDate} max={maxDateString} placeholder='Select Fixture date' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Time</div>
                        <input required={true} type='time' name='time' value={fixturedata?.time} onChange={postData} placeholder='Set Time' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                <div className="grid justify-center  sm:grid-cols-1 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Ground Address</div>
                        <input maxLength={50} required={true} type='text' name='groundaddress' value={fixturedata?.groundaddress} onChange={postData} placeholder='Enter Team Contact' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
            
                </div>    
                <div className="mt-6 w-full gap-3">
                    <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Messege</div>
                    <textarea maxLength={250} name='messege' value={fixturedata?.messege} onChange={postData} rows="2" className="block text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow" placeholder="Write short msg, rule or information..."></textarea>
                </div>
            
                <div className="grid sm:flex justify-center mt-10 mb-40">
                    <button disabled={loading} type='submit'  className=" rounded border-2 border-neutral-900 duration-100 hover:bg-white py-3 px-12 text-white hover:text-black font-semibold bg-neutral-900 shadow">{loading ? <>loading...</> : id !== '0' ? <span>Update</span>:<span>Create</span>}</button>
                </div>
                
                </form>
                {
                    user?.teamId && id !== '0'  ?
                    <>
                    
                    <div className='flex mt-40 mb-10'>
                    <button onClick={() =>{setDeleting(!deleting)}} className=" rounded-lg border border-red-600 hover:border-red-600 py-3 px-4 text-red-600 hover:text-white hover:bg-red-700 font-semibold">
                        Delete Fixture
                    </button>
                    <div className="text-sm text-red-700 font-medium mt-3 ml-10">
                        Warning: fixture will not be deleted once both teams joined the fixture !
                    </div>
                    </div>
                    </>:<></>
                }   
            </div>
    </div>  
    <ToastContainer />
    </>  )
}

export default CreateFixture