import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Home, SingleGround, MyGround, Teams, SingleTeam, Requests, UpdateProfile, Players, MyTeam,
   Login, TeamGig, MyBooking, Grounds, GroundGig, PlayerDetail, CreateTeam, Fixtures, CreateFixture, 
   SingleFixture, TeamFixtures,
   PrivacyPolicy,
   TermsOfService,
   CreateGround} from "./pages"
import { useRecoilValue } from 'recoil';
import {Navbar, Footer, PrivateRoute, ShowNavbar, PageNotFound} from "./components"
import userAtom from './atoms/userAtom';


function App() {
  const user = useRecoilValue(userAtom)
  return (
    <BrowserRouter>
      <ShowNavbar>
        <Navbar/>
      </ShowNavbar>
      <Routes>
        <Route path='*' element={<PageNotFound />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} /> 
        <Route path="/" element={<Home />} />
        <Route path="/grounds" element={<Grounds />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/singleteam/:id" element={<SingleTeam />} />
        <Route path="/players" element={<Players />} />
        <Route path="/singleground/:id" element={<SingleGround />} /> 
        <Route path="/playerdetail/:id" element={<PlayerDetail />} />
        <Route path="/fixtures" element={ <Fixtures /> } />
        <Route path="/singlefixture/:id" element={<SingleFixture />} />
        <Route path='/' element={<PrivateRoute />}>
          <Route path="/updateprofile" element={<UpdateProfile />} /> 
          <Route path="/groundgig" element={<GroundGig />} />
          <Route path="/myground" element={user?.groundaccess ? user?.groundId ? <MyGround /> : <GroundGig /> : <CreateGround />} />
          <Route path="/mybooking" element={<MyBooking />} />
          <Route path="/myteam/:id" element={user?.teamId ? <MyTeam /> : <CreateTeam />} />
          <Route path="/myteam" element={ <CreateTeam />} />
          <Route path="/teamgig" element={<TeamGig /> } />
          <Route path="/requests/:id" element={<Requests />} />
          <Route path="/fixturegig/:id" element={user?.teamId ? <CreateFixture /> : <CreateTeam />} />
          <Route path="/teamfixtures/:id" element={user?.teamId ? <TeamFixtures /> : <CreateTeam />} />
        </Route>
      </Routes>
      <ShowNavbar>
        <Footer />
      </ShowNavbar>
    </BrowserRouter>
  );
}

export default App;
