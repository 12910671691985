import React, {useEffect, useState} from 'react'
import { ToastContainer ,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import userAtom from '../../atoms/userAtom';
import teamAtom from '../../atoms/teamAtom';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import dots from '../../images/dots.png'
import playerImg from '../../images/defplayer.png'
import editlogo from '../../images/editlogo.png'
import Loading from '../../components/Loading';
import { FixtureCard } from '../../components';
import { Helmet } from 'react-helmet-async';


const MyTeam = () => {

  const{id} = useParams()
  const user = useRecoilValue(userAtom);
  const[team, setTeam]= useRecoilState(teamAtom)
  const[options, setOptions] = useState(false)
  const[current, setCurrent] = useState(null)
  const[players, setPlayers] = useState([])
  const[fixtures, setFixtures] = useState([])
  const[active, setActive] = useState([])
  const[loading, setLoading] = useState(false)
  const[pageloading, setPageLoading] = useState(false)

  const getTeam = async () => {
    if(pageloading) return
    setPageLoading(true)
    try {
      const resteam = await axios.get(`${process.env.React_App_Server}/teams/team/${id}`)
      const resfixtures = await axios.get(`${process.env.React_App_Server}/fixtures/past?teamId=${id}`)
      console.log(resfixtures.data)
      setTeam(resteam.data);
      setFixtures(resfixtures.data)
    } catch (error) {
      console.log(error);
    }
    finally {
      setPageLoading(false)
    }
  };

  const getActiveFixtures = async () => {
    if(pageloading) return
    setPageLoading(true)
    try {

      const res = await axios.get(`${process.env.React_App_Server}/fixtures/all?teamId=${id}`)
      setActive(res.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setPageLoading(false)
    }
  };

  const getPlayers = async () => {
    try {  
      const res = await axios.get(`${process.env.React_App_Server}/users/all?gig.myteam=${id}`)
      setPlayers(res.data.users)
    } catch (error) {
      console.log(error);
    }
  };

  const setCaptain = async(name)=>{
    try {
      const res = await fetch(`${process.env.React_App_Server}/teams/captain/${user?.teamId}`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
          },
          body: JSON.stringify({ name }),
          });
          console.log(res)
          const data = await res.json(); // updated user object
          if (data.error) {
          toast.error(data.error);
          return;
          }
          toast.info("Team Captain Updated!");
          } catch (error) {
              console.log(error);
            toast.error("Error");
          }
          finally{
            getTeam()
            getPlayers()
            setOptions(false)
          }
  }

  const[remove, setRemove] = useState(false)
  const[playerId, setPlayerId] = useState(null)

  const LeaveTeam = async()=>{
    try {
      const res = await fetch(`${process.env.React_App_Server}/teams/leaveteam/${user?.teamId}/${playerId}`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
          }
          });
          console.log(res)
          const data = await res.json(); // updated user object
          if (data.error) {
          toast.error(data.error);
          return;
          }
          toast.info("Player Removed from Team!");
          } catch (error) {
              console.log(error);
            toast.error("Error");
          }
          finally{
            getTeam()
            getPlayers()
            setOptions(false)
            setRemove(false)
          }
  }

  const handleActivate =async()=>{
    try {
      const res = await fetch(`${process.env.React_App_Server}/teams/disable/${user?.teamId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwtToken")
        }
      });
      const data = await res.json(); // updated user object
      if (data.error) {
      toast.error(data.error);
      return;
      }
      setTeam(null);
      toast.success("Team Activated");
    } catch (error) {
        console.log(error);
      toast.error("Error");
    }finally{
      getTeam()
    }
}

  useEffect(() => {
    if(id){
      getTeam()
      getPlayers()
      getActiveFixtures()
    }
  },[]);

  const Options = (index)=>{
    setOptions(!options)
    setCurrent(index)
  }
  const RemovePlayer = (index)=>{
    setRemove(!remove)
    setPlayerId(index)
  }
  
  if(pageloading){
    return <Loading />
  }

  return (
    <>
    <Helmet>
      <title>My Team</title>
      <meta name='description' content='Scout the best player and create fixtures to play with best teams'></meta>
      <link rel='canonical' href='/myteam'/>
    </Helmet>
    {
        remove ? 
        <>
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setRemove(false)}} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this Slot?</h3>
                        <button onClick={LeaveTeam} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button onClick={() =>{setRemove(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>:
        <></>
       }
       {loading ? 
        <div className='w-full flex justify-center items-center min-h-screen'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
      <>
         {
          team?.disabled &&(
            <div className="flex justify-center items-center fixed top-[30px] sm:top-[50px] lg:top-[60px] left-0 right-0 z-30 p-4 overflow-x-hidden overflow-y-auto  h-[calc(100%-1rem)] max-h-full backdrop-blur">
              <div className="relative w-full max-w-md max-h-full">
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                      <div className="p-6 text-center">
                          <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                          </svg>
                          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Activate your Team!</h3>
                          <button onClick={handleActivate} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                              Activate
                          </button>
                      </div>
                  </div>
              </div>
          </div>
          ) 
        } 
          <div className="w-full text-black bg-slate-50  pb-40">
            <div className="relative h-[180px] sm:h-[350px] md:h-[500px] lg:h-[500px] w-full rounded-b-xl overflow-hidden">
                <img className='absolute h-full w-full object-cover object-center' src={team?.banner} alt='Banner'/>
            </div>
              <div className='-mt-5 sm:-mt-10 z-20 relative mx-5 sm:mx-10 lg:mx-20 grid justify-center md:flex py-2 sm:py-6 md:py-16 border-2 rounded-lg shadow-xl shadow-neutral-400 mb-2 bg-white'>
                <div className=' md:w-[30%] grid justify-center sm:py-3 '>
                  <div className=' relative h-24 w-full lg:h-48 lg:w-48 flex justify-center items-center rounded overflow-hidden'>
                    <img className='absolute h-full  object-cover ' src={team?.logo} alt='Logo' />
                  </div>
                  <div className='text-lg sm:text-2xl md:text-3xl whitespace-nowrap  truncate text-center font-bold text-neutral-800 mt-4'>{team?.name}</div>
                </div>
                <div className="py-3 px-3 sm:px-5 md:w-[70%]">
                    <div className='flex w-full justify-between'>
                      <div className='text-base sm:text-2xl font-medium font-sans h-[70px] sm:h-[100px]  overflow-truncate w-[90%] overflow-hidden'>{team?.bio}</div>
                      <div>
                        <Link to={"/teamgig"}><img className='h-4 w-4 sm:h-6 sm:w-6' src={editlogo} alt='logo'/> <div className='text-[10px] sm:text-xs'>edit</div></Link>          
                      </div>
                    </div>
                    <div className="text-[10px] sm:text-sm flex gap-3 mt-6 font-medium">
                      <div className=""><span className=" text-gray-600"></span> {team?.contact},</div>
                      <div>{team?.city}</div>
                      <div>{team?.address}</div>
                    </div>
                   <div className="text-[10px] sm:text-sm flex gap-3 sm:gap-16 mt-3 ">
                      <div className="font-medium"><span className="text-[10px] text-gray-600">Home: </span>{team?.homeground}</div>
                      <div className="font-medium">{team?.aside}<span className="text-[10px] text-gray-600">-a-side</span></div>
                      <div className="font-medium"><span className="text-[10px] text-gray-600">Players: </span>{team?.players?.length}</div>
                      <div className="font-medium"><span className="text-[10px] text-gray-600">Captain: </span>{team?.captain}</div>
                   </div>
                   <div className='mt-8 sm:mt-10 flex justify-between'>
                    <Link to={"/teamfixtures/"+ team?._id} className="px-3 sm:px-10 py-1 text-[9px] sm:text-sm font-medium hover:text-neutral-800 border-2 border-neutral-800 bg-neutral-800 hover:bg-white text-white rounded-full">Manage Fixtures</Link>
                    <Link to={"/requests/"+ team?._id} className="px-3 py-1 text-[9px] sm:text-sm font-medium text-neutral-800 border-2 border-neutral-800 hover:bg-neutral-800 hover:text-white rounded-full">player requests</Link>
                   </div>
                    
                </div>
              </div>
              <div className='w-full flex justify-center my-10'>
                <div className=' border-2 border-gray-500 flex gap-1 sm:gap-5 justify-center px-3 sm:px-5'>
                  { team && team.history?.slice(-5).map((card, index) =>(
                        <div className='py-1 gap-1 sm:gap-3'>
                          {
                            card === 'W' ? <div className='font-medium text-white w-7 h-7 text-xs sm:text-base sm:h-10 sm:w-10 bg-green-600 rounded-full flex justify-center items-center'>W</div> : card === 'L' ? <div className='font-medium text-white w-7 sm:w-10 h-7 sm:h-10 text-xs sm:text-base bg-red-600 rounded-full flex justify-center items-center'>L</div> : card === 'D' ? <div className='font-medium text-white w-7 sm:w-10  h-7 sm:h-10 text-xs sm:text-base bg-gray-600 rounded-full flex justify-center items-center'>D</div> : <div className='font-medium text-white w-7 h-7 sm:h-10 sm:w-10 text-xs sm:text-base bg-gray-300 rounded-full flex justify-center items-center'>X</div>
                          }
                        </div>
                    ))
                  }
                </div>
              </div>
              <div className="px-5 sm:px-10 lg:px-20 w-full my-5 sm:my-10 lg:my-20 min-h-[300px] bg-slate-50 text-black">
                <div className='text-lg sm:text-2xl lg:text-4xl font-medium'>Active Fixtures</div>
                {
                  active?.length === 0 ?
                  <div className='flex min-h-[300px] items-center justify-center text-center text-lg font-medium text-gray-500'>
                    No Active Fixtures goto Manage Fixtures to create Fixtures
                  </div> 
                  :
                  <div className="mt-5 sm:mt-10 lg:mt-20 grid gap-10 justify-center w-full">
                  { active && active?.map((card, index) =>(
                        <FixtureCard key={index} card={card} />
                    ))}
                </div>
                }
                
              </div>
              <div className='px-5 sm:px-10 lg:px-20 mt-10 sm:mt-20 lg:mt-40 mb-5 sm:mb-10 lg:mb-20 min-h-[300px]'>
                <div className=" flex justify-between mb-5 sm:mb-10 lg:mb-20">
                  <div className="text-lg sm:text-2xl lg:text-4xl font-medium">Players</div>
                </div>
                <div className="flex gap-6 sm:gap-16 ">
                  {
                    players?.map((player, index)=>(
                      <div onClick={()=>Options(index)} key={index}  className=" w-[160px]  border text-neutral-900 rounded-xl shadow-xl bg-white">
                        {
                          (options && (index === current)) ? 
                          <div className='grid duration-300 hover:ease-in-out text-xs text-white gap-1 py-1 px-1 absolute ml-40  border bg-neutral-800 rounded'>
                              <Link to={'/playerdetail/' + player._id} className='hover:bg-neutral-500 py-1 px-1'>
                                View Profile
                              </Link>
                              <button className='hover:bg-neutral-500 py-1 px-1' onClick={()=>setCaptain(player.username)}>
                                Make Captain
                              </button>
                              <button onClick={()=>{RemovePlayer(player._id)}} className='hover:bg-red-700 py-1 px-2'>
                                Remove Player
                              </button>
                          </div> : <></>
                        }
                        <div className='relative w-full h-[140px] flex justify-end bg-cover rounded-t-xl' style={{ backgroundImage: `url('${player?.profilePic || playerImg}')`}}>
                          <button className='bg-white h-6 w-6 rounded-full flex justify-center'><img className='h-5' src={dots} alt='options'/></button>
                        </div>
                        <div className='absolute ml-[65px] -mt-6 z-20 bg-white p-1 rounded-full '>
                            <img className='h-6 w-6 rounded-lg' src={player?.gig?.teamlogo} alt="logo"/>
                        </div>
                        <div className='flex  text-sm font-medium px-3 mt-3 w-full'>
                            <div className='whitespace-nowrap truncate text-black'>{player?.username}</div> 
                        </div>
                        <div className='flex justify-between text-xs px-3 mb-1'>
                            <div className=' whitespace-nowrap mt-1 text-neutral-900'><span className='text-[10px] text-gray-500'>aside: </span> {player?.gig?.aside || "NA"}</div> 
                            <div className=' whitespace-nowrap mt-1 text-neutral-900'><span className='text-[10px] text-gray-500'>pos: </span> {player?.gig?.position || "NA"}</div> 
                        </div>                      
                    </div>
                    ))
                  }
                </div>
              </div>
              <div className="px-5 sm:px-10 lg:px-20 mt-10 sm:mt-20 lg:mt-40 mb-5 sm:mb-10 lg:mb-20 min-h-[300px]">
                <div className='text-lg sm:text-2xl lg:text-4xl font-medium'>Past Fixtures</div>
                {
                  fixtures?.length === 0 ?
                  <div className='flex justify-center text-center'>
                    No Past Fixtures goto Manage Fixtures to create Fixtures
                  </div> 
                  :
                  <div className="mt-5 sm:mt-10 lg:mt-20 grid gap-5 sm:gap-10 justify-center w-full">
                  { fixtures && fixtures?.map((card, index) =>(
                        <FixtureCard key={index} card={card} />
                    ))}
                </div>
                }
                
              </div>
          </div>
        </>  
      }
    <ToastContainer />
    </>
  )
}

export default MyTeam