import React from 'react'
import manageteam from "../../images/manageteam.webp"
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const CreateTeam = () => {
  return (
    <>
    <Helmet>
      <title>Create Team</title>
      <meta name='description' content='Create your own team and become manager'></meta>
      <link rel='canonical' href='/teamgig'/>
    </Helmet>
    <div className='grid grid-cols-3 min-h-screen p-20'>
        <div className='col-span-2 relative h-full w-full overflow-hidden rounded-lg'>
            <img className='absolute h-full w-full object-cover' src={manageteam} alt='' />
        </div>
        <div className='col-span-1 flex justify-center items-center w-full'>
            <div className='h-[40%] grid justify-center items-center'>
                <div className='text-2xl font-medium'>Become Manager</div>
                <div className='w-full flex justify-center'>
                    <Link to={"/teamgig"} className='h-14 w-36 text-center py-3 text-lg duration-100 bg-neutral-900 hover:bg-white border-2 border-neutral-900 rounded text-white hover:text-neutral-900 font-medium'>Create Team</Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CreateTeam