import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import { useRecoilState} from 'recoil';
import teamAtom from '../../atoms/teamAtom';
import { Link, useNavigate } from 'react-router-dom';
import teamimg from '../../images/createteam.webp'
import defclub from '../../images/defclub.png'
import banner from '../../images/teambanner.webp'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { imagedb } from '../../firebase-config';
import userAtom from '../../atoms/userAtom';
import { Helmet } from 'react-helmet-async';



const TeamGig = () => {

    const [user, setUser] = useRecoilState(userAtom);
    const[team, setTeam] = useRecoilState(teamAtom);
    const [logoloading, setLogoLoading] = useState(false);
    const [bannerloading, setBannerLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const route = useNavigate()


    const [teamdata, setTeamData] = useState({
        logo: team?.logo || "",
        banner: team?.banner || "",
        homeground:team?.homeground || "",
        city: team?.city || "",
        name: team?.name || "",
        aside: team?.aside || "",
        manager: team?.manager || "",
        contact: team?.contact || "",  
        bio: team?.bio || "",          
    })

    let name, value;
    const postData=(e)=>{
        name = e.target.name;
        value = e.target.value;

        setTeamData({...teamdata, [name]:value})
    }


    const handleDelete =async()=>{
        try {
          const res = await fetch(`${process.env.React_App_Server}/teams/disable/${user?.teamId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
            }
          });
          const data = await res.json(); // updated user object
          if (data.error) {
          toast.error(data.error);
          return;
          }
          setTeam(null);
          toast.warning(data);
          setDeleting(false)
          setTimeout(() =>{
            route("/myteam/"+user.teamId) 
          },2000)
        } catch (error) {
            console.log(error);
          toast.error("Error");
        }
    }


    useEffect(() => {
        if(user.teamId && !team){
            route("/")
        }
    },[])
    
    const uploadImage = async (e) => {
        if (!e.target.files[0]) return;
        const imageFile = e.target.files[0];
    
        if (imageFile.size > 1049000) {
          toast.warning("Upload Image less than 1MB");
          return;
        }
    
        if (logoloading) return;
        setLogoLoading(true);
    
        try {
          const imgRef = ref(imagedb, `team/${user._id}/teamlogo`);
          await uploadBytes(imgRef, imageFile);
          const url = await getDownloadURL(imgRef);
          setTeamData((prevData) => ({ ...prevData, logo: url }));
          toast.success("Logo Uploaded");
        } catch (error) {
          console.log(error);
          toast.error("Error uploading logo");
        } finally {
          setLogoLoading(false);
        }
      };
    
      const uploadBanner = async (e) => {
        if (!e.target.files[0]) return;
        const imageFile = e.target.files[0];
    
        if (imageFile.size > 2098000) {
          toast.warning("Upload Image less than 2MB");
          return;
        }
    
        if (bannerloading) return;
        setBannerLoading(true);
    
        try {
          const imgRef = ref(imagedb, `team/${user._id}/teambanner`);
          await uploadBytes(imgRef, imageFile);
          const url = await getDownloadURL(imgRef);
          setTeamData((prevData) => ({ ...prevData, banner: url }));
          toast.success("Banner Uploaded");
        } catch (error) {
          console.log(error);
          toast.error("Error uploading banner");
        } finally {
          setBannerLoading(false);
        }
      };

      

    const createTeam = async (e) => {
        e.preventDefault();
        if (updating) return;
        setUpdating(true);
   
        if(user.teamId){
            try {
            const res = await fetch(`${process.env.React_App_Server}/teams/update/${user.teamId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("jwtToken")
                },
                body: JSON.stringify({ ...teamdata }),
                });
                const data = await res.json(); // updated user object
                if (data.error) {
                toast.error(data.error);
                return;
                }
                setTeam(data);
                toast.success("Team Updated Successfully!");
                setTimeout(() =>{
                    route("/myteam/"+user.teamId) 
                  },2000)
                } catch (error) {
                    console.log(error);
                  toast.error("Error");
                }finally {
                    setUpdating(false);
                }
        }else{
            try{
            const res = await fetch(`${process.env.React_App_Server}/teams/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("jwtToken")
                },
                body: JSON.stringify({ ...teamdata, owner: user?._id }),
                });
                console.log(res)
                const data = await res.json(); // updated user object
                if (data.error) {
                toast.error(data.error);
                return;
                }
                setTeam(data);
                toast.success("Team Created Successfully!");
                setUser({teamId:data._id})
                setTimeout(() =>{
                    route("/myteam/"+data._id) 
                  },1000)
                } catch (error) {
                    console.log(error);
                  toast.error("Error");
                } finally {
                    setUpdating(false);
                }
        }     
  };


  return (
    <>
    <Helmet>
      <title>Create Team</title>
      <meta name='description' content='Create your Team and Become Manager'></meta>
      <link rel='canonical' href='/teamgig'/>
    </Helmet>
       {
        deleting ? 
        <>
            <div className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() =>{setDeleting(false)}} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to disable your Team?</h3>
                        <button onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button onClick={() =>{setDeleting(false)}} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>:
        <></>
       }
        <div className="min-h-screen flex justify-center items-center bg-slate-50 text-black pt-[72px]">
            <div className="lg:w-[80%] ">
            <div className="flex justify-center items-center bg-bottom bg-cover rounded-xl overflow-hidden" style={{ backgroundImage: `url('${teamimg}')`}}>
                <div className='flex justify-center items-center text-center text-white font-bold text-6xl w-full h-72 bg-black/90 animate-pulse  backdrop-blur-[3px] rounded-xl'>Manage Team Info</div>
            </div>
            {
                user.teamId &&(
                    <div className='mt-5 flex justify-center'>
                        <Link to={"/myteam/"+user.teamId} className=' px-12 py-3 bg-neutral-900 hover:bg-white border-2 border-neutral-900 text-white hover:text-neutral-900 font-medium duration-100 rounded'>Go Back</Link>  
                    </div>
                )
            }
             <div className="mt-20 mb-10 text-lg sm:text-2xl  font-semibold">Team Logo</div>
                    <div className="flex ">
                        <div className='flex justify-center items-center h-52 w-52 rounded-full border-4 border-neutral-900 bg-gray-200 overflow-hidden'>
                        <img className='h-full w-full rounded flex justify-center items-center' src={team?.logo || defclub} alt=''/>
                        </div>
                        {
                            logoloading ?    <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div> :
                            <div className='h-14 mt-20 grid justify-center items-center ml-8'>
                                <input className='text-xs' accept='image/jpeg, image/png, image/jpg, image/jfif, image/webp' type='file' onChange={(e)=>uploadImage(e)}/>
                                <div className='text-xs text-gray-600 font-normal'>max 1MB</div>
                            </div>
                        }
                        
                    </div>
                <div className="mt-20 mb-10 text-lg sm:text-2xl  font-semibold">Team Banner</div>
                <div className="flex ">
                    <div className='relative h-[300px] w-[800px] rounded-lg border overflow-hidden'>
                        <img className='absolute object-cover h-full w-full rounded' src={team?.banner || banner} alt=''/>
                    </div>
                    {
                        bannerloading ? 
                        <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...</span>
                        </div> :
                        <div className='h-14 mt-20 grid justify-center items-center ml-8'>
                            <input className='text-xs' accept='image/jpeg, image/png, image/jpg, image/jfif, image/webp' type='file' onChange={(e)=>uploadBanner(e)}/>
                            <div className='text-xs text-gray-600 font-normal'>max 2MB</div>
                        </div>
                    }
                   
                </div>
            <form onSubmit={createTeam} className="px-20" required={true}>
               
                <div className="pl-10 sm:pl-0  mt-20 mb-10 text-lg sm:text-2xl text-black font-semibold">Team Info</div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Team Name</div>
                        <input required type="text" name='name' value={teamdata?.name} onChange={postData} placeholder='Enter Team Name' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full mt-3 sm:mt-0">
                        <div className='sm: text-neutral-700 font-medium text-sm sm:text-base'>Team A-side <span className="text-xs"> ( e.g; 11-a-side )</span></div>
                        <select required={true} type="text" name='aside' value={teamdata?.aside} onChange={postData} className=' text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'>
                            <option className="bg-neutral-700 text-white">0</option>
                            <option className="bg-neutral-700 text-white">5</option>
                            <option className="bg-neutral-700 text-white">7</option>
                            <option className="bg-neutral-700 text-white">11</option>
                            <option className="bg-neutral-700 text-white">All</option>
                        </select>
                    </div>
                </div>
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Contact</div>
                        <input maxLength={11} required={true} type='tel' name='contact' value={teamdata?.contact} onChange={postData} placeholder='Enter Team Contact' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full mt-3 sm:mt-0">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Home Ground</div>
                        <input required={true} type="text" name='homeground' value={teamdata?.homeground} onChange={postData} placeholder='Enter Nearest Ground' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
          
             
                <div className="grid justify-center  sm:grid-cols-2 mt-6 w-full gap-3">
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>City</div>
                        <input required={true} type="text" name='city' value={teamdata?.city} onChange={postData} placeholder='Enter Team city' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                    <div className="w-full">
                        <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Manager Name</div>
                        <input required={true} type="text" name='manager' value={teamdata?.manager} onChange={postData} placeholder='Person who manages the Team' className='text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow'/>
                    </div>
                </div>
                <div className="mt-6 w-full gap-3">
                    <div className=' text-neutral-700 font-medium text-sm sm:text-base'>Team Bio</div>
                    <textarea maxLength={100} name='bio' value={teamdata?.bio} onChange={postData} rows="1" className="block text-xs py-4 sm:text-sm  pl-10 w-full rounded focus:border-neutral-900 focus:outline-none border-2 border-neutral-500 shadow" placeholder="Write short line about your team..."></textarea>
                </div>
              
                <div className="grid sm:flex justify-center mt-10 mb-40">
                    <button disabled={updating} type='submit' className=" rounded border-2 border-neutral-900 duration-100 hover:bg-white py-3 px-12 text-white hover:text-black font-semibold bg-neutral-900 shadow">{updating ? <>loading...</> : team?._id ? <span>Update</span>:<span>Create</span>}</button>
                </div>
                 
                </form>
                {
                    team?._id ?
                    <>
                   
                    <div className='flex mt-40 mb-10'>
                    <button onClick={() =>{setDeleting(!deleting)}} className=" rounded-lg border border-red-600 hover:border-red-600 py-3 px-4 text-red-600 hover:text-white hover:bg-red-700 font-semibold">
                        Disable team
                    </button>
                    <div className="text-sm text-red-700 font-medium mt-3 ml-10">
                        Warning: If you disabled your team, the Team will be invisble to everyone else and Player might Leave the Team!
                    </div>
                    </div>
                    </>:<></>
                }   
                </div>
      </div>  
      <ToastContainer />
    </>
  )
}

export default TeamGig