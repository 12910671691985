import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import userAtom from '../../atoms/userAtom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imagedb } from '../../firebase-config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Helmet } from 'react-helmet-async';


const Profile = () => {

    const[user, setUser] = useRecoilState(userAtom);
    const [img, setImg] = useState(user?.profilePic)
    const [updating, setUpdating] = useState(false);
   
    const [userdata, setUserData] = useState({
        username:user?.username,
        email:user?.email,
        contact:user?.gig?.contact,
        city:user?.gig?.city,
        age:user?.gig?.age,
        position:user?.gig?.position,
        aside:user?.gig?.aside,
        bio:user?.gig?.bio,
        password:"",
        profilePic: user?.profilePic || ""
      });
    
      let name, value;
      const postData =(e)=>{
        name = e.target.name;
        value = e.target.value;
    
        setUserData({...userdata, [name]:value})
      }

      const uploadImage =async(e)=>{
        const imageFile = e.target.files[0];

        if (imageFile.size > 1049000) {
          toast.warning("Upload Image less than 1MB");
          return;
        }
    
        try {
          const imgRef = ref(imagedb, `player/${user._id}`)
          uploadBytes(imgRef, imageFile).then(data =>
            getDownloadURL(data.ref).then(url=>{
              setImg(url)
            })
          )
          handleSubmit(e)
        } catch (error) {
          console.log(error)
        }
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
        if (updating) return;
        setUpdating(true);
     
        try {
          const res = await fetch(`${process.env.React_App_Server}/users/update/${user._id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("jwtToken")
            },
            body: JSON.stringify({ ...userdata, profilePic: img }),
          });
          const data = await res.json(); // updated user object
          if (data.error) {
            toast.error(data.error);
            return;
          }
          setUser(data);
          localStorage.setItem("user-threads", JSON.stringify(data));
          toast.success("Profile updated successfully");
        } catch (error) {
          toast.error("Error");
        } finally {
          setUpdating(false);
          setTimeout(()=>{
            window.location.reload();
          },3000)
        }
      };

    
  return (
    <>
    <Helmet>
      <title>Update Profile</title>
      <meta name='description' content='update profile to join best teams'></meta>
      <link rel='canonical' href='/updateprofile'/>
    </Helmet>
        <div className="grid px-40 min-h-screen">
        <form onSubmit={handleSubmit}>
        <div className=" grid gap-4 w-full text-black font-medium border-2 border-neutral-900 px-20 py-5 my-20 rounded-2xl shadow-xl">
          <div className="text-2xl fint-semibold mb-3">Update Profile</div>
          <div className="flex">
            <div className='relative flex justify-center items-center h-32 w-32 rounded-full border-4 border-neutral-900 overflow-hidden'>
              <img className='absolute object-cover h-full w-full rounded-full' src={img} alt=''/>
            </div>
            <div className='h-14 mt-10 grid justify-center items-center ml-8'>
                <input className='text-xs' accept='image/jpeg, image/png, image/jpg, image/jfif' type='file' onChange={(e)=>uploadImage(e)}/>
                <div className='text-xs text-gray-600 font-normal'>max 1MB</div>
            </div>
          </div>
          <div className='w-full grid grid-cols-2 mt-5 gap-10'>
            <div className="text-left grid">Username
              <input required autoComplete='off' value={userdata.username}  onChange={postData} type="text" name='username' className="py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900" placeholder="Enter Username"/>
            </div>
            <div className="text-left grid">Contact
              <input maxLength={11} autoComplete='off' value={userdata.contact}  onChange={postData} type='tel' name='contact' className="py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900" placeholder="Enter Contact"/>
            </div>
          </div>
          
          <div className='w-full grid grid-cols-2 gap-10'>
            <div className="text-left grid">City
              <input autoComplete='off' value={userdata.city}  onChange={postData} type="text" name='city' className="py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900" placeholder="Enter City"/>
            </div>
            <div className="text-left grid">Age
              <input autoComplete='off' value={userdata.age}  onChange={postData} type='number' name='age' className="py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900"/>
            </div>  
          </div>
          <div className='w-full grid grid-cols-2 gap-10'>       
            <div className="text-left grid">Position
              <select type="text" name='position' value={userdata?.position} onChange={postData} className='py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900'>
                <option className="bg-neutral-800 text-white">GK</option>
                <option className="bg-neutral-800 text-white">CB</option>
                <option className="bg-neutral-800 text-white">LB</option>
                <option className="bg-neutral-800 text-white">RB</option>
                <option className="bg-neutral-800 text-white">CDM</option>
                <option className="bg-neutral-800 text-white">CM</option>
                <option className="bg-neutral-800 text-white">CAM</option>
                <option className="bg-neutral-800 text-white">RM</option>
                <option className="bg-neutral-800 text-white">LM</option>
                <option className="bg-neutral-800 text-white">RW</option>
                <option className="bg-neutral-800 text-white">LW</option>
                <option className="bg-neutral-800 text-white">ST</option>
              </select>
            </div>
            <div className="text-left grid">Aside
              <select type="text" name='aside' value={userdata?.aside} onChange={postData} className='py-3  text-black text-sm  pl-6 bg-transparent rounded shadow shadow-neutral-400 border-2 border-neutral-500 focus:outline-none focus:border-neutral-900'>
                  <option className="bg-neutral-800 text-white">0</option>
                  <option className="bg-neutral-800 text-white">5</option>
                  <option className="bg-neutral-800 text-white">7</option>
                  <option className="bg-neutral-800 text-white">11</option>
                  <option className="bg-neutral-800 text-white">All</option>
              </select>
            </div> 
          </div>
          <div className="text-left grid">Bio
            <textarea maxLength={100} name='bio' value={userdata?.bio} onChange={postData} rows="1" className="block p-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-neutral-500 focus:outline-none focus:border-neutral-900" placeholder="Write your bio here e.g, I am Midfield Maestro..."></textarea>
          </div>
          <div className='flex justify-center'> 
            <button disabled={updating}  type='submit' className='py-3 mt-6 px-10 text-white text-sm bg-neutral-900 hover:bg-neutral-700 rounded'>{updating ? <span className='text-xs'>Loading...</span>: <span>Update</span> }</button>
          </div>
        </div>
        </form>
        </div>
        <ToastContainer />
    </>
  )
}

export default Profile