import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import userAtom from '../atoms/userAtom'

export function useAuthStatus () {

    const [user, setUser] = useRecoilState(userAtom)
    const[loggedIn, setLoggedIn] = useState(false)
    const[status, setStatus] = useState(true)

    useEffect(()=>{
        
            if(user){
                setLoggedIn(true)
            }
            setStatus(false)
    },[user])

  return {loggedIn, status}
}