import React, { useEffect } from 'react'
import { ToastContainer ,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch';
import player from '../../images/defplayer.png'
import { Helmet } from 'react-helmet-async';


const SeeRequests = () => {
    const {id} = useParams()
    const {data, loading, reFetch} = useFetch(`${process.env.React_App_Server}/users/all?sendrequests=${id}`)


    const AcceptRequest = async(playerId)=>{
      try {
        const res = await fetch(`${process.env.React_App_Server}/teams/acceptrequest/${id}/${playerId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwtToken")
          }
        });
        const data = await res.json(); // updated user object
        if (data.error) {
          toast.error(data.error);
          return;
        }
        console.log(data)
        toast.success("Request Accepted!");
      } catch (error) {
        console.log(error)
        toast.error(error)
      }finally{
        reFetch()
      }
    }

    const RejectRequest = async(playerId)=>{
      try {
        const res = await fetch(`${process.env.React_App_Server}/teams/rejectrequest/${id}/${playerId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwtToken")
          }
        });
        const data = await res.json(); // updated user object
        if (data.error) {
          toast.error(data.error);
          return;
        }
        console.log(data)
        toast.warn("Request Rejected!");
      } catch (error) {
        console.log(error)
        toast.error(error)
      }finally{
        reFetch()
      }
    }


    useEffect(() =>{
      reFetch()
    },[])



  return (
    <>
    <Helmet>
      <title>Requests</title>
      <meta name='description' content='Sign the best players'></meta>
      <link rel='canonical' href='/requests'/>
    </Helmet>
     <div className="grid w-full px-6 lg:px-16 py-20 bg-slate-50 text-neutral-900 min-h-screen">
        <div className="w-full h-24 grid justify-center text-center font-semibold ">
            <div className=" font-mono text-6xl">Requests</div>
            <div className="flex w-full justify-center text-center text-sm overflow-hidden mt-2">
                See which team wants you to play for them.
            </div>
        </div>
       {loading ? 
        <div className='w-full flex justify-center'>
            <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div> 
        : 
      <div className=" w-full  px-6 lg:px-16 py-20 min-h-screen bg-clate-50 text-black">
        <div className='flex gap-10 font-medium'> 
          <div className="bg-green-600 px-6 rounded text-white py-2">Total Requests</div>
          <div className='text-2xl mt-1'>{data?.users?.length}</div>
        </div>
        <div className="mt-10 grid grid-cols-3 gap-10 justify-start">
        {data?.users?.map((card, index) =>(
                <div key={index} className=' py-2 rounded px-3 border w-[370px] border-neutral-200 shadow-lg'>
                    <div className='flex w-full'>
                      <img className="rounded-full h-20 w-20" alt='' src={card?.profilePic || player} />
                      <div className="pl-3 w-full">
                        <div className='flex justify-between w-full'>
                          <div>
                            <div className='text-xl font-semibold  truncate whitespace-nowrap text-neutral-900'>{card?.username}</div>
                            <div className='text-[11px]'>{card?.gig?.contact}</div>
                          </div>
                          
                          <div className='text-sm mt-2 ml-3'>{card?.gig?.position}</div>
                          <div className='grid gap-2 ml-3'>
                            <button  onClick={()=>{AcceptRequest(card._id)}} className=' text-xs text-white px-2 py-1 rounded bg-green-600 hover:bg-green-800'>Accept</button>
                            <button  onClick={()=>{RejectRequest(card._id)}} className='cursor-pointer text-xs text-white px-3 py-1  rounded bg-red-600 hover:bg-red-800'>Reject</button>
                          </div>
                        </div>   
                        
                        <div className='flex mt-3 text-[11px]'>
                          <div>{card?.gig?.city}</div>
                          <div className='ml-5'>{card?.gig?.aside || <>unknown</>} side player</div>
                          <div className='ml-5'>{card?.gig?.dob}</div>  
                        </div>          
                      </div>
                    </div>
                    <div className='text-xs my-2 h-[32px] w-full overflow-auto text-gray-800'>{card?.gig?.bio}</div>  
                </div>
            ))}
        </div>
      </div>
      }
      </div>
    <ToastContainer />
      
    </>
  )
}

export default SeeRequests