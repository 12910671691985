import React from 'react'
import { Helmet } from 'react-helmet-async'
import pagenotfound from '../context/pagenotfound.json'
import Lottie from 'lottie-react';

const PageNotFound = () => {
  return (
    <>
        <Helmet>
            <title>OrKhylo Page Not Found</title>
            <meta name="description" content="Page Not Found"></meta>
            <link rel='canonical' href='*'/>
        </Helmet>
        <div className='h-screen flex justify-center items-center'>
            <Lottie className='h-72 w-72 sm:h-44 sm:w-44 relative bg-white rounded-xl' animationData={pagenotfound} loop={true} />
        </div>
    </>
  )
}

export default PageNotFound