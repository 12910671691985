import React, { useEffect, useState } from 'react'
import defclub from '../../images/defclub.png'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Lottie from "lottie-react";
import playeranimation from '../../context/playeranimation.json'
import { useRecoilState } from 'recoil';
import userAtom from '../../atoms/userAtom';
import { Helmet } from 'react-helmet-async';

const PlayerDetail = () => {

    const { id } = useParams()
    const[player, setPlayer] = useState("")
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
     
       const getPlayer = async () => {
        if(loading) return
        setLoading(true)
         try {
           const res = await axios.get(`${process.env.React_App_Server}/users/user?_id=${id}`);
           setPlayer(res.data)
     
         } catch (error) {
           console.log("No Player Found");
         }finally{
          setLoading(false)
         }
       };
       getPlayer();
   },[])



  return (
    <>
    <Helmet>
      <title>Player Career</title>
      <meta name='description' content='Career of player'></meta>
      <link rel='canonical' href='/playerdetail'/>
    </Helmet>
        <div className='w-full px-5 sm:px-10 lg:px-20 bg-slate-50 py-10 sm:py-20 min-h-screen'>
          <div className='w-full h-[230px] grid sm:flex gap-5 sm:gap-10 lg:gap-20 py-6  bg-neutral-900  justify-center sm:justify-between border-2 px-5 sm:px-10 lg:px-20 rounded-2xl'>
            <div className='grid order-last sm:order-first justify-center sm:justify-between items-end'>
              <div className='hidden sm:flex text-white font-medium text-lg sm:text-xl lg:text-3xl text-end underline mb-8'>Complete your Profile to Join Best Teams</div>
              {
                player?._id === user?._id ?
                <div className='flex justify-end'>
                  <Link to={'/updateprofile'} className='bg-white text-xs sm:text-base whitespace-nowrap sm:text-normal rounded px-3 sm:px-8 py-2 sm:py-3 font-medium border-2 border-white hover:bg-neutral-200 duration-200'>Update Profile</Link>
                </div> 
                :<></>
              }    
            </div>      
            <Lottie className='h-32 w-32 sm:h-44 sm:w-44 relative bg-white rounded-xl' animationData={playeranimation} loop={true} />
          </div>
          {
        loading ? 
          <div className='w-full flex justify-center mt-10 sm:mt-20 lg:mt-40'>
              <div className=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
              </div>
          </div> 
        : 
          <div className='w-full flex justify-center mt-10 sm:mt-20 lg:mt-40 mb-5 sm:mb-10 lg:mb-20'>
            <div className='w-[95%] sm:w-[80%]'>
            <div className=' grid grid-cols-1 sm:grid-cols-2 gap-3 mb-10 text-white'>
              <div className='col-span-1 grid text-center  py-10 justify-center bg-neutral-800 overflow-hidden rounded-2xl'>
                <div className='relative h-32 w-32 border-4 border-white rounded-full overflow-hidden'>
                  <img className='absolute h-full w-full object-cover object-center ' src={player?.profilePic} alt=''/>
                </div>
                <div className='font-medium text-sm sm:text-base'>{player?.username}</div>
                <div className='font-medium text-sm sm:text-base'>{player?.gig?.contact || '03xxxxxxxxx'}</div>
              </div>
              <div className='col-span-1 grid grid-cols-2 grid-rows-2 gap-3'>
                  <div className='col-span-1 py-3 overflow-hidden bg-neutral-800 rounded-2xl grid justify-center items-center text-center'>
                    <div className='flex justify-center text-xl sm:text-2xl'>City</div>
                    <div className='font-medium text-sm sm:text-lg'>{player?.gig?.city || 'NA'}</div>
                  </div>
                  <div className='col-span-1 py-3 overflow-hidden bg-neutral-800 rounded-2xl grid justify-center items-center text-center'>
                    <div className='flex justify-center text-xl sm:text-2xl'>Position</div>
                    <div className='font-medium text-sm sm:text-lg'>{player?.gig?.position || 'NA'}</div>
                  </div>
                  <div className='col-span-1 py-3 overflow-hidden bg-neutral-800 rounded-2xl grid justify-center items-center text-center'>
                    <div className='flex justify-center text-xl sm:text-2xl'>Aside</div>
                    <div className='font-medium text-sm sm:text-lg'>{player?.gig?.aside || 'NA'}</div>
                  </div>
                  <div className='col-span-1 py-3 overflow-hidden bg-neutral-800 rounded-2xl grid justify-center items-center text-center'>
                    <div className='flex justify-center text-xl sm:text-2xl'>Age</div>
                    <div className='font-medium text-sm sm:text-lg'>{player?.gig?.age || 'NA'}</div>
                  </div>
              </div>
              
            </div>
            <div className='grid sm:grid-cols-2 w-full gap-3'>
                  <div className='grid justify-center text-center py-5 bg-neutral-800 rounded-2xl'>
                    <div className='relative h-32 w-32 rounded mx-auto'>
                      <img className='absolute h-full w-full object-cover rounded' src={player?.gig?.teamlogo} alt=''/>
                    </div>
                    <div className='text-white font-medium text-2xl mt-2'>{player?.gig?.teamname || "Free Agent"}</div>
                  </div>
                  <div className='grid justify-center items-center text-center py-5 bg-neutral-800 rounded-2xl text-white font-medium'>
                    <div className='text-xl sm:text-2xl'>
                      Bio:
                    </div>
                    <div className='text-white font-medium text-sm sm:text-lg mt-2'>{player?.gig?.bio || "NA"}</div>
                  </div>
              </div>
              </div>
          </div>
        }
        </div>
    </>
  )
}

export default PlayerDetail