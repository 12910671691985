import React from 'react'
import { Helmet } from 'react-helmet-async'

const PrivacyPolicy = () => {
  return (
    <>
         <Helmet>
            <title>OrKhylo  -Privacy Policy</title>
            <meta name="description" content="OrKhylo Privacy Policy"></meta>
            <link rel='canonical' href='/privacypolicy'/>
        </Helmet>
    <div className='p-20 '>
        <div className='font-medium text-lg'>Privacy Policy</div>
        <div className='text-sm mt-3'>Last Updated: 2/23/2024</div>
        <div className='font-medium text-lg mt-10'>Introduction</div>
        <div className='text-sm mt-3'>We, the operators of OrKhylo, are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we use and safeguard that information, and your rights regarding your data. By using our website, you agree to the terms of this Privacy Policy.</div>
        <div className='font-medium text-lg mt-10'>User Authentication and Google OAuth:</div>
        <div className='text-sm mt-3'>
            •	Our website allows users to seamlessly become OrKhylo users by leveraging their Google Account credentials through Google OAuth.<br></br>
            •	We do not access, use, store, and/or share any Google user data. The user authentication process is solely handled by Google OAuth for a secure login experience.
        </div>
        <div className='font-medium text-lg mt-10'>Information Collection:</div>
        <div className='text-sm mt-3'>
            Non-Personal Information:<br></br>
            •	We may collect non-personal information about usage and service operation to evaluate how visitors use our website.<br></br>
            •	Aggregate information, such as referral data, pages viewed, time spent on our website, may be gathered for analytical purposes.<br></br><br></br>
            Personal Information:<br></br>
            •	We do not intentionally collect personal information from minors, and our website is not targeted towards children.<br></br>
            •	The website may use cookies to enhance user experience, track user preferences, and customize content. Users can modify their browser settings to manage cookies.<br></br><br></br>
            Google OAuth Callback:<br></br>
            •	When a user successfully logs in using Google OAuth, the response is stored in the user's local storage for seamless login during subsequent visits.
        </div>
        <div className='font-medium text-lg mt-10'>How We Use Your Information:</div>
        <div className='text-sm mt-3'>
            •	Non-personal data may be aggregated for reporting about the usability, performance, and effectiveness of our website.<br></br>
            •	We do not sell, rent, or lease any individual's personal information. Personal information is used for authentication and to enhance user experience on our platform.
        </div>
        <div className='font-medium text-lg mt-10'>Information Sharing:</div>
        <div className='text-sm mt-3'>
            •	We do not share personal information with third parties for marketing purposes.<br></br>
            •	We reserve the right to release personal information to protect our systems, business, or if required by law.
        </div>
        <div className='font-medium text-lg mt-10'>Security:</div>
        <div className='text-sm mt-3'>
            •	We employ security measures, including firewalls, to protect user information. However, no server is 100% secure, and users are responsible for maintaining the secrecy of their personal information.
        </div>
        <div className='font-medium text-lg mt-10'>Opting Out:</div>
        <div className='text-sm mt-3'>
            •	Users may opt-out of receiving electronic newsletters, announcements, surveys, or other communications by following the provided directions.
        </div>
        <div className='font-medium text-lg mt-10'>Changes to Privacy Policy:</div>
        <div className='text-sm mt-3'>
            •	Our Privacy Policy may be updated, and the latest version will be posted on our website. Users are encouraged to check for changes during each visit.
        </div>
        <div className='font-medium text-lg mt-10'>Contact Information:</div>
        <div className='text-sm mt-3'>
            If you have any questions regarding this privacy policy, please contact us at <br></br>
            orkhylo@gmail.com.
        </div>
    </div>
    </>
  )
}

export default PrivacyPolicy